import { Grid } from '@ltvco/refresh-lib/theme';
import { StyledLink, StyledLinkAction } from './components';
import { constants } from 'appConstants';
import { useNavigate } from 'react-router-dom';

const { links } = constants;

const { supportLink, FaqLink, dontSellInfoLink, affiliateLink, doDontsLink } = links;

export function HelpLinks({
  contactUsHandler,
}: {
  contactUsHandler: () => void;
}) {
  const navigate = useNavigate();
  const helpfulLinks = [
    {
      href: supportLink,
      textContent: 'Customer Care',
    },
    {
      href: '#',
      textContent: 'Contact Us',
      clickHandler: contactUsHandler,
    },
    {
      href: doDontsLink,
      textContent: "Do's & Dont's",
    },
    {
      href: FaqLink,
      textContent: 'FAQ',
    },
    {
      href: dontSellInfoLink,
      textContent: 'Do Not Sell or Share My Personal Information',
    },
    {
      href: affiliateLink,
      textContent: 'Affiliates',
    },
  ];

  return (
    <>
      {helpfulLinks.map((link, index) => (
        <Grid key={`help-link-${index}`} item xs={12} sm={3}>
          {link.clickHandler ? (
            <StyledLinkAction onClick={link.clickHandler}>
              {link.textContent}
            </StyledLinkAction>
          ) : (
            <StyledLink to={link.href} target="_blank">
              {link.textContent}
            </StyledLink>
          )}
        </Grid>
      ))}
    </>
  );
}
