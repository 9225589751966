import { Box, Button, Card, Text } from '@ltvco/refresh-lib/theme'
import { openReportInNewTab, SerializedContactTeaserData, useReportRedirect, useSearchContact } from '@ltvco/refresh-lib/v1';
import { SearchType } from './SmartSearchModal';
import { AlternateEmailRounded, Email, Person, PhoneIphone } from '@mui/icons-material';
import { theme } from 'theme';
import { capitalizeEachWord, capitalizeString, formatPhone, useScreenSize } from '@ltvco/refresh-lib/utils';
import { useAppConstantsContext } from '@ltvco/refresh-lib/ctx';

export interface SmartSearchResultCardProps {
  reportType: string;
  reportValue: string;
}

const SmartSearchResultCard = ({ reportType, reportValue }: SmartSearchResultCardProps) => {
  const iconsMap: Record<string, JSX.Element> = {
    'names': <Person sx={{ color: theme.palette.primary.main, fontSize: '20px' }} />,
    'emails': <Email sx={{ color: theme.palette.primary.main, fontSize: '20px' }} />,
    'phones': <PhoneIphone sx={{ color: theme.palette.primary.main, fontSize: '20px' }} />,
    'usernames': <AlternateEmailRounded sx={{ color: theme.palette.primary.main, fontSize: '20px' }} />
  }
  const { redirectToSearchContactUrlNewTab } = useReportRedirect();
  const { isMedium } = useScreenSize();
  const {
    links: { baseUrl },
  } = useAppConstantsContext();

  let searchContactResult: SerializedContactTeaserData = {
    contacts: [],
    total: 0
  };

  if (reportType === 'names') {
    reportValue = capitalizeEachWord(reportValue);

    const { data = [] } = useSearchContact({
      contactSearchParams: { name: reportValue },
    });

    searchContactResult = data as SerializedContactTeaserData;
  }

  if (reportType === 'phones') {
    reportValue = formatPhone(reportValue);
  }

  const handleSubmit = () => {
    const report = reportType.slice(0, -1);

    if (report == 'name') {
      redirectToSearchContactUrlNewTab({ [report]: reportValue })
      return;
    }

    openReportInNewTab(
      {
        [report]: reportValue,
        searchType: report as SearchType,
      },
      baseUrl
    );
  }

  return (
    <Card
      className='hover-shadow'
      variant='outlined'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: '20px',
        flexDirection: isMedium ? 'column' : 'row'
      }}>
      <Box>
        <Text
          fontSize={14}
          sx={{ marginBottom: '12px' }}>
          {reportType == 'names' ? (
            searchContactResult.contacts ? (
              <span>
                <strong>{searchContactResult.contacts.length} Results</strong> found for
              </span>) : (
              <span>
                <strong>Searching Results</strong> for
              </span>
            )
          ) : (
            <span>
              <strong>{capitalizeString(reportType.slice(0, -1))} Report</strong> found for
            </span>)}
        </Text>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              backgroundColor: '#EDF3FD',
              borderRadius: '50%',
              width: '44px',
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0
            }}>
            {iconsMap[reportType]}
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '12px'
          }}>
            <Text
              color={'primary'}
              fontWeight={700}
              fontSize={24}
              sx={{ wordBreak: 'break-all' }}
            >
              {reportValue}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Button size='large' color='secondary' variant='contained' sx={{
          width: isMedium ? '100%' : '172px',
          marginTop: isMedium ? '11px' : 0
        }} onClick={handleSubmit}>
          {reportType == 'names' ? 'See Results' : 'View Report'}
        </Button>
      </Box>
    </Card>
  )
}

export default SmartSearchResultCard