import { Box, styled } from '@ltvco/refresh-lib/theme';
import {
  AccountListsListItem,
  useAccountListsV2,
  LizanoListInfo,
} from '@ltvco/refresh-lib/v1';
import { useNavigate, useParams } from 'react-router-dom';

const ListsContainer = styled(Box)(({ theme }) => ({
  '.ways-to-add-reports-container > .rfrsh-btn': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  },
}));

export function ListsItem() {
  const { data, isLoading } = useAccountListsV2();
  const { listName } = useParams<{ listName: string }>();
  const navigate = useNavigate();

  if (isLoading) {
    return null;
  }

  const list: LizanoListInfo | undefined = data?.find(
    ({ name }: { name: string }) => name === listName
  ) as LizanoListInfo;

  const redirectToSearchContactsPage = () => {
    navigate('/search/contact');
  }

  return (
    <ListsContainer>
      <AccountListsListItem
        list={list}
        hidePropertyReportToggleButton={true}
        customRedirectPath={redirectToSearchContactsPage}
        customClassName='ways-to-add-reports-container'
      />
    </ListsContainer>
  );
};
