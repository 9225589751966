import { AccountNotes, useAccountNotes } from '@ltvco/refresh-lib/v1';
import { EditNote } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { theme } from 'theme';
import { Button, Stack, Text } from '@ltvco/refresh-lib/theme';
import { useNavigate } from 'react-router-dom';
import emptyNotesIcon from 'images/empty-notes-icon.svg';

const FlexContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled(Typography)(({ theme }) => ({
  alignSelf: 'baseline',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.875rem',
  fontWeight: 'bold',
  fontFamily: "'Lato', sans-serif"
}));

const NoNotesText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(1),
}));

const AddNotesText = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(2.5),
}));

export function Notes() {
  const { data } = useAccountNotes();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Notes - PeopleSmart';
  }, []);

  return (
    <FlexContainer>
      <Title variant="h1">
        <EditNote
          sx={{
            color: theme.palette.primary.main,
            marginRight: '4px',
            height: '40px',
            width: '40px',
          }}
          fontSize="large"
        />
        Private Notes
      </Title>
      {
        data?.length === 0
          ? (
            <Stack justifyContent={'center'} alignItems={'center'} height={'50vh'}>
              <img src={emptyNotesIcon} alt="empty-notes-icon" width={'80px'} />
              <NoNotesText>Looks like you don’t have any saved notes.</NoNotesText>
              <AddNotesText variant="h2">
                Add notes to reports as you search!
              </AddNotesText>
              <Button
                variant='contained'
                sx={{ width: '150px' }}
                onClick={() => navigate('/search/contact')}
              >
                Search
              </Button>
            </Stack>
          )
          : <AccountNotes />
      }
    </FlexContainer>
  );
}
