import { createTheme } from '@ltvco/refresh-lib/theme';

export const theme = createTheme({
  typography: {
    button: { textTransform: 'none', fontSize: '1rem', fontWeight: 'bold' },
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "'Lato', sans-serif",
    h1: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      margin: '0 0 12px 0',
      lineHeight: 1.2,
    },
    caption: {
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.2,
    },
  },
  components: {
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#e2e2e2',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: 12,
          },
          div: {
            height: 40,
            backgroundColor: '#fff',
          },
          '& .MuiFormLabel-root': {
            top: -8,
          },
          '& .Mui-focused': {
            top: 0,
          },
          '& .MuiFormLabel-filled': {
            top: 0,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1B61DB',
      dark: '#132369',
      light: '#1742A2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EDF3FD',
      dark: '#ADC4EB',
      light: '#F2F6FD',
      contrastText: '#1B61DB',
    },
    info: {
      main: '#EDF3FD',
      dark: '#ADC4EB',
      contrastText: '#1B61DB',
    },
    success: {
      main: '#1B61DB',
      light: '#1742A2',
      contrastText: '#fff',
    },
    error: {
      main: '#DC0015',
      light: '#FAE0E3',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f8f8f8',
    },
    barChart: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#FACEDA',
      dark: '#FCE7ED',
      contrastText: '#000',
    },
    lineChart: {
      main: 'rgba(0, 0, 0, 0.87)',
      light: '#757575',
      dark: '#DC0015',
      contrastText: '#000',
    },
    progress: {
      main: '#009E60',
      light: '#1AB86B',
      contrastText: '#fff',
    },
    premium: {
      main: '#F5C419',
      light: '#F2DA3E',
      contrastText: '#000000',
    },
    maps: {
      main: '#1F2E64',
      light: '#3388FF',
    },
    listingPins: {
      main: '#EC413D',
      light: '#4AA351',
      dark: '#4A3B8F',
      contrastText: '#81b44c',
    },
    listingPinsVisited: {
      main: '#f8a09e',
      light: '#9FCBA2',
      dark: '#9F97C1',
    },
    marketInsights: {
      main: '#000',
      contrastText: '#fff',
    },
    threatDetection: {
      main: '#DC0015',
      light: '#E1FAEA',
      dark: '#81B44C',
      contrastText: '#FFF1F1',
    },
  },
});
