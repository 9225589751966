import {
  useSession,
  AddPhoneNumberModal,
  ChangePasswordModal,
  EditNameModal,
  UpdateEmailModal,
} from '@ltvco/refresh-lib/v1';
import { PasswordResetModal } from '@ltvco/refresh-lib/v2';

export function AccountEditModals({
  editing,
  finishEditing,
}: {
  editing: string | null;
  finishEditing: () => void;
}) {
  const {
    session: { account },
  } = useSession();

  return (
    <>
      <EditNameModal
        firstName={account?.account.user_info.first_name || ''}
        lastName={account?.account.user_info.last_name || ''}
        isOpen={editing === 'EDIT_NAME'}
        onSubmit={finishEditing}
        onCloseHandle={finishEditing}
      />

      <UpdateEmailModal
        email={account?.account.user_info.email || ''}
        isOpen={editing === 'EDIT_EMAIL'}
        onSubmit={finishEditing}
        onCloseHandle={finishEditing}
      />

      <AddPhoneNumberModal
        isOpen={editing === 'ADD_PHONE_NUMBER'}
        onSubmit={finishEditing}
        onCloseHandle={finishEditing}
      />

      <ChangePasswordModal
        isOpen={editing === 'CHANGE_PASSWORD'}
        onSubmit={finishEditing}
        onCloseHandle={finishEditing}
      />

      <PasswordResetModal
        isOpen={editing === 'PASSWORD_RESET_EMAIL'}
        onCloseHandle={finishEditing}
      />
    </>
  );
}
