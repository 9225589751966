import {
  useNavigate,
  Navigate,
  Routes,
  Route,
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { initialize } from '@ltv-apm-modules/react';
import Analytics from 'analytics';
import { useQueryParam, StringParam } from 'use-query-params';
import googleTagManager, {
  GoogleTagManagerConfig,
} from '@analytics/google-tag-manager';
import {
  type ResponseError,
  ErrorRequestData,
} from '@ltvco/refresh-lib/utils';
import {
  Footer,
  SearchData,
  invalidateSession,
  useSession,
  ComplianceFormPage,
  CompliancePermittedPage,
  ComplianceProhibitedPage,
  ComplianceRecomplyPage,
  SessionProvider,
  isUserLoggedIn,
  ResetPasswordRequestForm,
  MagicLinkModal,
  ForgotLoginModal,
  ContactOptionsModal,
  closeSnackbar,
  ConfirmCancelModal,
  CancellationReasonsModal,
  Header,
  SsoPromoBanner,
  TosModalLegalError,
  NonCompliancePage,
} from '@ltvco/refresh-lib/v1';
import {
  styled,
  Button,
  ErrorBoundary,
} from '@ltvco/refresh-lib/theme';
import {
  DashboardParent,
  Login,
  Report,
  AuthenticatedRoute,
  Billing,
  Lists,
  ListsItem,
  ResetPassword,
  Reactivation,
  PublicRoute,
  NotFound,
  ErrorPage,
  UpgradePlanPage,
  ReportVelocityCaptcha,
  RecentReportsPage,
  Monitoring,
  SearchContactPage,
  Notes,
  Fraud,
} from 'routes';
import { constants } from 'appConstants';
import 'App.css';
import { Recycling } from 'components/recycling/Recycling';
import QueryWrapper from 'components/queryWrapper/QueryWrapper';
import { EmailVerification } from 'components/EmailVerification';
import { useEffect, useRef, useState } from 'react';
import type { SideMenuItem } from '@ltvco/refresh-lib/v1';
import { AboutUsLinks, CustomDisclaimer, HelpLinks } from './utils/footerLinks';
import packageInfo from '../package.json';
import { Growthbook } from 'components/Growthbook';
import { LibContextWrapper } from 'components/LibContextWrapper';
import { goToLogin } from 'utils/goToLogin';
import { BannerMigrationCta } from '@ltvco/refresh-lib/v2';
import { version } from '@ltvco/refresh-lib';
import { createAppConfig } from '@ltvco/refresh-lib/ctx';

let otel: any = null;
if (
  import.meta.env.VITE_TARGET_ENV === 'production' ||
  import.meta.env.VITE_TARGET_ENV === 'staging'
) {
  otel = initialize({
    name: 'refresh-peoplesmart',
    url: import.meta.env.VITE_APM_URL,
    apikey: import.meta.env.VITE_APM_KEY,
  });
}

declare global {
  interface Window {
    env: Record<string, string>;
  }
}

const HeaderContainer = styled('div')`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
`;

const SsoBannerContainer = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const AppContainer = styled('div')(({ theme }) => ({
  marginTop: '55px',
  backgroundColor: theme.palette.background.default,
  paddingBottom: theme.spacing(0.25),
}));

const AppContent = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  margin: 'auto',
}));

const AppFooter = styled('div')(({ theme }) => ({
  maxWidth: '1400px',
  margin: theme.spacing(0),
  padding: theme.spacing(0, 2, 4),
  position: 'relative',
  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.up('xs')]: {
    margin: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 2, 4, 9),
  },
}));

const MenuItem = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.fontSize * 1.125,
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  svg: {
    marginRight: theme.spacing(1),
  },
}));

const LibVersion = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)} 0 0 0`,
  fontSize: theme.typography.fontSize * 0.75,
}));

function flattenMenuItems(menuItems: SideMenuItem[]): SideMenuItem[] {
  return menuItems.flatMap(({ childItems, ...item }) => [
    item,
    ...(childItems?.length ? flattenMenuItems(childItems) : []),
  ]);
}

function App() {
  const analytics = Analytics({
    app: 'refresh-beenverified',
    debug: true,
    plugins: [
      googleTagManager({
        containerId: 'GTM-PVGF4RR',
        dataLayer: (window as any)?.dataLayer ?? [],
      } as GoogleTagManagerConfig),
    ],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useSession();
  const [isCoreLoginRedirect] = useQueryParam(
    'is_core_login_redirect',
    StringParam
  );
  const [loginType] = useQueryParam('login_type', StringParam);
  const [isAuthenticated, setIsAuthenticated] = useState(isUserLoggedIn());
  const [isContactOptionsModalOpen, setIsContactOptionsModalOpen] =
    useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isCancelFinalStepModalOpen, setIsCancelFinalStepModalOpen] =
    useState(false);
  const loginPageContainerRef = useRef(null);
  const [tosState, setTosState] = useState<{
    modalOpen: boolean;
    requestData: ErrorRequestData['body'] | null;
  }>({
    modalOpen: false,
    requestData: null,
  });

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (
      isCoreLoginRedirect === 'true' &&
      !sessionStorage.getItem('has_tracked_core_login_redirect')
    ) {
      analytics.track('site_event', {
        event_name: 'login_attempt',
        type: loginType || 'web',
        success: true,
      });

      sessionStorage.setItem(
        'has_tracked_core_login_redirect',
        isCoreLoginRedirect
      );
    }
  }, [isCoreLoginRedirect]);

  const flatMenu = flattenMenuItems(constants.config.sideMenuItems);
  const menuItems = flatMenu.map((item) => {
    return (
      <MenuItem to={item.route} data-cy={`menu_${item['data-cy']}`}>
        {item.icon} {item.text}
      </MenuItem>
    );
  });

  async function handleLogoutClick() {
    await invalidateSession();
    setIsAuthenticated(isUserLoggedIn());
    closeSnackbar();
    goToLogin('https://www.peoplesmart.com');
  }

  const defaultConfig = createAppConfig();

  function isResponseError(error: any): error is ResponseError {
    return error.response !== undefined;
  }

  function logError(
    context: string,
    error: Error | ResponseError,
    url: string | undefined
  ) {
    const isProdEnv = import.meta.env.VITE_TARGET_ENV === 'production';
    if (!isProdEnv || !otel) return;

    const { account } = session;
    const { api } = otel;
    if (api.isOTELInitialized()) {
      if (account) {
        api.setUser({
          id: account?.account.user_info.user_code,
          email: account?.account.user_info.email,
        });
      }
      let errorMsg = `${context}: ${error.message}, Cause: ${error.cause}`;
      if (isResponseError(error)) {
        errorMsg = `${errorMsg}, Status: ${error.response.status
          }, Data: ${JSON.stringify(error.response)}`;
      }
      if (url) {
        errorMsg += `, URL: ${url}`;
      }
      api.pushError(new Error(errorMsg));
    }
  }

  function trackEventGA4(eventToTrack: object, eventName?: string) {
    const targetEnv = import.meta.env.VITE_TARGET_ENV;
    if (targetEnv === 'dev') return;
    analytics?.track(eventName || 'site_event', eventToTrack);
  }

  function trackEvent(category: string, action: string, label?: string) {
    analytics.track('ua_event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  const appConfig = {
    ...defaultConfig,
    routingUtils: {
      ...defaultConfig.routingUtils,
      navigate: navigate,
      location: location,
      useSearchParams,
    },
    logError: logError,
    trackEvent: (category: string, action: string, label?: string) => {
      analytics.track('ua_event', {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      });
      // GA4
      analytics.track('site_event', {
        event_name: action,
        type: label,
        subtype: category,
      });
    },
  };

  const onLoginSuccess = () => {
    let next = '/';

    if (location.state && location.state.next) {
      next = location.state.next;
    }

    navigate(
      next === '/' ||
        (next.startsWith('/report') && !next.includes('permalink'))
        ? '/dashboard'
        : next
    );
    setIsAuthenticated(isUserLoggedIn());
  };

  const contactUsHandler = () => {
    setIsContactOptionsModalOpen(true);
  };

  const handleSearchSuccess = ({
    reportType,
    searchParams,
    permalink,
  }: SearchData) => {
    const url = `/report/${reportType}?${searchParams}&permalink=${permalink}`;
    navigate(url);
  };

  const handleCancellationModalClose = (reason: string | undefined) => {
    setIsCancelFinalStepModalOpen(false);
  };

  return (
    <ErrorBoundary
      redirectFunction={() => {
        navigate('/error');
      }}
      location={location}
      logFunction={logError as any}
      trackEventFunction={trackEventGA4}
    >
      <QueryWrapper 
        handleLogout={handleLogoutClick} 
        trackEventGA4={trackEventGA4}
        setTosState={setTosState}
        >
          <SessionProvider isAuthenticated={isAuthenticated}>
            <Growthbook>
              <LibContextWrapper
                trackEventGA4={trackEventGA4}
                logError={logError}
                trackEvent={trackEvent}
                handleLogoutClick={handleLogoutClick}
              >
                <EmailVerification>
                  <div className="app">
                    <HeaderContainer>
                    <SsoBannerContainer>
                      <SsoPromoBanner />
                      <BannerMigrationCta />
                    </SsoBannerContainer>
                    <Header
                        onSearchSuccess={handleSearchSuccess}
                        handleLogout={handleLogoutClick}
                        showMobilePrimarySearchFormTabs={true}
                        enableSmartSearch={true}
                      />
                    </HeaderContainer>
                    <AppContainer id="app-container">
                      <AppContent>
                        <Routes>
                          <Route element={<NotFound />} path={'*'} />
                          <Route element={<ErrorPage />} path={'/error'} />
                          <Route
                            index
                            element={<Navigate to={`/dashboard`} />}
                          />
                          <Route
                            path={`/login`}
                            element={
                              <PublicRoute>
                                <Login
                                  onLoginSuccess={onLoginSuccess}
                                  ref={loginPageContainerRef}
                                />
                              </PublicRoute>
                            }
                          >
                            <Route
                              path="reset-password-request"
                              element={
                                <ResetPasswordRequestForm
                                  containerRef={loginPageContainerRef}
                                  open={false}
                                />
                              }
                            />
                            <Route
                              path="magic-link"
                              element={
                                <MagicLinkModal
                                  containerRef={loginPageContainerRef}
                                  open={false}
                                />
                              }
                            />
                            <Route
                              path="forgot-email"
                              element={
                                <ForgotLoginModal
                                  containerRef={loginPageContainerRef}
                                  open={false}
                                />
                              }
                            />
                          </Route>
                          <Route
                            path={`/reset-password`}
                            element={
                              <PublicRoute>
                                <ResetPassword />
                              </PublicRoute>
                            }
                          />
                          <Route
                            path={`dashboard`}
                            element={
                              <AuthenticatedRoute>
                                <DashboardParent
                                  contactUsHandler={contactUsHandler}
                                  handleLogout={handleLogoutClick}
                                />
                              </AuthenticatedRoute>
                            }
                          >
                            <Route
                              index
                              element={
                                <AuthenticatedRoute>
                                  <RecentReportsPage />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/reports`}
                              element={
                                <AuthenticatedRoute>
                                  <RecentReportsPage />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/lists`}
                              element={
                                <AuthenticatedRoute>
                                  <Lists />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/lists/:listName`}
                              element={
                                <AuthenticatedRoute>
                                  <ListsItem />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/notes`}
                              element={
                                <AuthenticatedRoute>
                                  <Notes />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/monitoring`}
                              element={
                                <AuthenticatedRoute>
                                  <Monitoring />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`billing`}
                              element={
                                <AuthenticatedRoute>
                                  <Billing />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={`/dashboard/fraud`}
                              element={
                                <AuthenticatedRoute>
                                  <Fraud
                                    onSearchSuccess={handleSearchSuccess}
                                  />
                                </AuthenticatedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path={`/report/:reportType`}
                            element={
                              <AuthenticatedRoute>
                                <Report
                                  onSearchSuccess={handleSearchSuccess}
                                />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/search/contact`}
                            element={
                              <AuthenticatedRoute>
                                <SearchContactPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/noncompliance`}
                            element={
                              <AuthenticatedRoute>
                                <NonCompliancePage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-form`}
                            element={
                              <AuthenticatedRoute>
                                <ComplianceFormPage 
                                  link="/noncompliance"
                                  grantAccessToAccountWithoutCriminalData={
                                    false
                                  }
                                 />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-permitted`}
                            element={
                              <AuthenticatedRoute>
                                <CompliancePermittedPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-prohibited`}
                            element={
                              <AuthenticatedRoute>
                                <ComplianceProhibitedPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/compliance-recomply`}
                            element={
                              <AuthenticatedRoute>
                                <ComplianceRecomplyPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/reactivation'}
                            element={
                              <AuthenticatedRoute>
                                <Reactivation />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            index={true}
                            path={'/upgrade/plan'}
                            element={
                              <AuthenticatedRoute>
                                <UpgradePlanPage
                                  contactUsHandler={contactUsHandler}
                                />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/captcha'}
                            element={
                              <AuthenticatedRoute>
                                <ReportVelocityCaptcha />
                              </AuthenticatedRoute>
                            }
                          />
                        </Routes>
                        <Recycling isAuthenticated={isAuthenticated} />
                      </AppContent>
                    </AppContainer>
                    <ContactOptionsModal
                      isOpen={isContactOptionsModalOpen}
                      setIsOpen={setIsContactOptionsModalOpen}
                      openConfirmCancelModal={() =>
                        setIsConfirmCancelModalOpen(true)
                      }
                    />
                    <ConfirmCancelModal
                      isOpen={isConfirmCancelModalOpen}
                      onClose={() => setIsConfirmCancelModalOpen(false)}
                      onCancel={() => {
                        setIsConfirmCancelModalOpen(false);
                        setIsCancelFinalStepModalOpen(true);
                      }}
                    />
                    <TosModalLegalError
                      tosState={tosState}
                      setTosState={setTosState}
                      handleSearchSuccess={handleSearchSuccess}
                    />
                    {isCancelFinalStepModalOpen && (
                      <CancellationReasonsModal
                        isOpen={true}
                        onClose={(reason) => {
                          handleCancellationModalClose(reason);
                        }}
                      />
                    )}
                    <Button
                      onClick={() =>
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: 'smooth',
                        })
                      }
                      sx={{
                        backgroundColor: 'secondary.main',
                        '&:hover': {
                          backgroundColor: 'secondary.main',
                        },
                        marginBlock: 2.5,
                      }}
                      fullWidth
                    >
                      Back to Top
                    </Button>
                    <AppFooter>
                      <Footer
                        contactUsHandler={contactUsHandler}
                        AboutUsLinks={AboutUsLinks}
                        DynamicHelpLinks={HelpLinks}
                        customDisclaimer={<CustomDisclaimer />}
                      />
                      <LibVersion>
                        {import.meta.env.VITE_TARGET_ENV === 'prod' ? (
                          <code>{version}</code>
                        ) : (
                          <code>
                            {packageInfo.dependencies['@ltvco/refresh-lib']}
                          </code>
                        )}
                      </LibVersion>
                    </AppFooter>
                  </div>
                </EmailVerification>
              </LibContextWrapper>
            </Growthbook>
          </SessionProvider>
      </QueryWrapper>
    </ErrorBoundary>
  );
}

export default App;
