import { useEffect, useState } from 'react';
import { Grid, useTheme } from '@ltvco/refresh-lib/theme';
import { 
  isZeroed,
  useScrollToSectionOnNavigate,
  fetchJobTitle,
  nameize,
} from '@ltvco/refresh-lib/utils';
import {
  ContactHeader,
  ContactEducationSection,
  DesktopNav,
  NotesSection,
  ReportLoading,
  ReportActionsTopBar,
  ReportNavMobile,
  ContactPhoneSection,
  ReportOptions,
  ContactEmailSection,
  ContactSocialSection,
  ContactEmploymentSection,
  AddressHistory,
  ReportFactory,
  ReportRating,
  ContactOverviewSection,
  RelatedContactsSection,
  ReportNullState,
  useReportMonitors,
  ReportChangesOverview,
  useReport,
  useRemouladeReportSnapshot,
  commonTypes,
  ContactCompany,
  useReportRedirect,
  openReportInNewTab,
  SerializedContactTeaserData,
  useSearchContact,
  Owner,
  ContactSearchParams,
  DebugMenu,
  peopleTypes,
} from '@ltvco/refresh-lib/v1';
import { ReportRouteProps } from './types';
import { getContactNavLinkData } from 'navLinkData/contactNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import imgCompany from '../../images/img_company_placeholder.svg';
import { useContext } from 'react';
import { RatsContainer } from './components';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface ContactReportProps extends ReportRouteProps {}

const reportType = 'contact';

export function ContactReportPage({
  permalink,
  isMonitored = false,
}: ContactReportProps) {
  const { routingUtils, trackEvent } = useContext(AppConfig);
  const { redirectToSearchContactUrl } = useReportRedirect();
  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const queryResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;

  const theme = useTheme();

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true, // This will filter out data with confidence < 50
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  const { reportIsMonitored } = useReportMonitors();

  let report = null;
  let reportUpgraded: boolean = false;

  if (queryResult?.data) {
    report = ReportFactory.create(queryResult.data, 'contact');
    reportUpgraded = queryResult.data.meta.report_upgraded;
  }

  let owner = {} as Owner;
  let contactNavLinkData;

  if (report) {
    owner = report.getOwner(reportOptions);
    document.title = `${report.contact} - PeopleSmart`;
  }

  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    addresses,
    identity,
  } = owner;

  jobs?.sort((a: peopleTypes.Job, b: peopleTypes.Job) => {
    const dateA = a.period?.start_date?.full
      ? new Date(a.period.start_date.full)
      : new Date(0);
    const dateB = b.period?.start_date?.full
      ? new Date(b.period.start_date.full)
      : new Date(0);

    const dateCompare = dateB.getTime() - dateA.getTime();
    if (dateCompare !== 0) {
      return dateCompare;
    }

    const companyA = a.company?.toLowerCase() || '';
    const companyB = b.company?.toLowerCase() || '';

    if (companyA == '' && companyB == '') {
      return 0;
    } else if (companyA == '') {
      return 1;
    } else if (companyB == '') {
      return -1;
    }

    return companyA.localeCompare(companyB);
  });

  const filterParsedAddresses = addresses
  ? addresses.filter((address) => address.parsed !== null)
  : [];

  let hasContactsByCompanyResults = false;
  let hasContactsByTitleResults = false;

  const job = jobs?.[0];
  const company = job ? job?.company : '';
  const title = job ? fetchJobTitle(job, '') : '';

  const aliases = identity
  ? identity.names.map((name) => nameize(name.full))
  : [];
aliases.shift();

  if (title !== '') {
    hasContactsByTitleResults = true;
  }

  if (company !== '') {
    hasContactsByCompanyResults = true;
  }

  let {
    data: searchContactResultByCompany = [],
    isFetched: relatedByCompanyFetched,
  } = useSearchContact({
    contactSearchParams: {
      company: company,
    },
  });

  searchContactResultByCompany =
    searchContactResultByCompany as SerializedContactTeaserData;

  const contactsByCompany =
    searchContactResultByCompany?.contacts?.filter(
      (searchResult) => searchResult.id !== identity?.source_ids?.[0]
    ) || [];

  if (report) {
    contactNavLinkData = getContactNavLinkData(
      owner,
      contactsByCompany.length,
      relatedByCompanyFetched,
    );
  }

  let searchData: ContactSearchParams = {
    title: title,
  };

  let {
    data: searchContactResultByTitle = [],
    isFetched: relatedByTitleFetched,
  } = useSearchContact({ contactSearchParams: searchData });

  searchContactResultByTitle =
    searchContactResultByTitle as SerializedContactTeaserData;

  const contactsByTitle =
    searchContactResultByTitle?.contacts?.filter(
      (searchResult) => searchResult.id !== identity?.source_ids?.[0]
    ) || [];

  const getCompanyUrl = (company: string) =>
    routingUtils.searchContactUrl({ company: company });

  const handleCompanyClick = (company: string | ContactCompany) => {
    if (typeof company === 'string') {
      redirectToSearchContactUrl({ company });

      return;
    }

    redirectToSearchContactUrl({ company: company.name });
  };

  const trackSubSections = (subsection?: string) => {
    trackEvent(
      `related contacts_same ${subsection}`,
      'search',
      'contact search'
    );
  };

  const handleRelatedContactCompanyClick = (
    company: string | ContactCompany,
    subsection?: string
  ) => {
    if (typeof company === 'string') {
      trackSubSections(subsection);
      redirectToSearchContactUrl({ company });

      return;
    }

    trackSubSections(subsection);
    redirectToSearchContactUrl({ company: company.name });
  };

  const handleViewReport = (contactId: string, subsection?: string) => {
    trackSubSections(subsection);
    openReportInNewTab(
      {
        contact_id: contactId,
        searchType: 'contact',
      },
      constants.links.baseUrl
    );
  };
  const mostRecentAddress = (addresses: commonTypes.Addresses) => {
    let filteredAddresses = addresses.filter(
      (address) => address.parsed?.city || address.parsed?.state
    );
    return filteredAddresses?.[0];
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={10} />;
  }

  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report) return <ReportLoading menuItems={14} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
          }}
        >
          {contactNavLinkData && (
            <DesktopNav
              navLinkData={contactNavLinkData}
              reportDataPoint={report.contact}
              // TODO:
              // subtitle={'<position> at <company>'}
              permalink={permalink}
            />
          )}
        </Grid>
        <Grid item sm={12} md={8} lg={8} marginTop={3}>
          {reportIsMonitored && contactNavLinkData && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={contactNavLinkData}
            />
          )}
          <RatsContainer>
            <ReportActionsTopBar
              reportPermaLink={permalink}
              reportTitle={report.contact}
              reportType={reportType}
              deleteCurrentReport={(permalink: string) => permalink}
              reportUpgraded={reportUpgraded}
            />
          </RatsContainer>
          <ContactOverviewSection
            personName={ownerName}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({
                showHighConfidenceDataOnly: value,
              });
            }}
            onCompanyClick={handleCompanyClick}
            getCompanyUrl={getCompanyUrl}
            contactOverviewItemsData={{
              job: jobs[0],
              address: mostRecentAddress(addresses),
              emails,
              phones,
              profiles,
              education: educations[0],
              identity: aliases,
            }}
          />

          <ContactEmailSection
            emailsCount={emails.length}
            personName={ownerName}
            emailList={emails}
            permalink={permalink}
          />

          <ContactPhoneSection
            phonesCount={phones.length}
            personName={ownerName}
            phoneList={phones}
            permalink={permalink}
          />

          <ContactSocialSection
            personName={ownerName}
            profiles={profiles}
            permalink={permalink}
          />

          <ContactEmploymentSection
            personName={ownerName}
            permalink={permalink}
            jobs={jobs}
            defaultCompanyLogo={imgCompany}
          />

          <ContactEducationSection
            educationsCount={educations.length}
            personName={ownerName}
            educationList={educations}
          />

          <AddressHistory
            addresses={filterParsedAddresses}
            addressCount={filterParsedAddresses.length}
            personName={ownerName}
            permalink={permalink}
            showCityAndStateAddress={true}
          />

          <RelatedContactsSection
            companyName={company || ''}
            personName={ownerName}
            hasContactsByCompanyResults={hasContactsByCompanyResults}
            isLoadingContactsByCompany={!relatedByCompanyFetched}
            hasContactsByTitleResults={hasContactsByTitleResults}
            isLoadingContactsByTitle={!relatedByTitleFetched}
            relatedContactsByCompany={contactsByCompany}
            relatedContactsByTitle={contactsByTitle}
            onCompanyClick={handleRelatedContactCompanyClick}
            onViewReport={handleViewReport}
            header={
              <ContactHeader
                cardBackgroundColor={theme.palette.primary.dark}
                textWidth={140}
                gridSpacing={10}
              />
            }
          />

          <NotesSection permalink={permalink} />

          <ReportRating
            permalink={permalink}
            rating={report.data.meta?.rating || null}
          />
        </Grid>
      </Grid>
      {contactNavLinkData && (
        <ReportNavMobile navLinkData={contactNavLinkData} />
      )}
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
