import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { constants } from 'appConstants';
import { useSession } from '@ltvco/refresh-lib/v1';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';

export const AccountInformationOptions = [
  {
    name: 'Edit Name',
    display: 'Edit Name',
    value: 'Edit Name',
    constantValue: 'EDIT_NAME',
  },
  {
    name: 'Edit Email',
    display: 'Edit Email',
    value: 'Edit Email',
    constantValue: 'EDIT_EMAIL',
  },
  {
    name: 'Add Phone Number',
    display: 'Edit Phone Number',
    value: 'Add Phone Number',
    constantValue: 'ADD_PHONE_NUMBER',
  },
  {
    name: 'Change Password',
    display: 'Change Password',
    value: 'Change Password',
    constantValue: 'CHANGE_PASSWORD',
  },
  {
    name: 'Edit Payment Info',
    display: 'Edit Payment Info',
    value: 'Edit Payment Info',
    constantValue: 'EDIT_PAYMENT_INFO',
  },
];

export const editOptionClicked = (
  editing: string | null,
  openModal: (open: boolean) => void,
  setEditing: Dispatch<SetStateAction<string | null>>,
  customOptions?: {
    name: string;
    display: string;
    value: string;
    constantValue: string;
  }[]
) => {
  const options = customOptions || AccountInformationOptions;
  if (editing === 'Edit Payment Info') {
    openModal(true);
  } else {
    setEditing(
      options.find((option) => {
        return option.name === editing;
      })?.constantValue || editing
    );
  }
};

export const GenericCtaCardProps = (navigate: NavigateFunction) => {
  // has_sso_connections
  const {
    session: { account },
  } = useSession();

  const has_sso_connections = account?.account?.user_info?.has_sso_connections;

  // AB Tests
  const isInSingleSignVariation = useFeatureIsOn('RTRN-68');

  const singleSignCta = {
    headerText: 'Connect or Edit Login Options',
    bodyText: 'Add your Google Login on top of your account password & email',
    buttonText: 'Edit Login Options',
    imgUrl: constants.images.imgSingleSignOnCta,
    onCtaClick: () => {
      window.open('https://www.peoplesmart.com/sso_connections', '_blank');
    },
  };

  const props = [];

  if (has_sso_connections || isInSingleSignVariation) {
    props.unshift(singleSignCta);
  }
  
  return { ctaCardProps: props, length: props.length };
};
