import { Box, styled } from '@ltvco/refresh-lib/theme';
import { AccountListsV2, useAccountListsV2 } from '@ltvco/refresh-lib/v1';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ListsContainer = styled(Box)(({ theme }) => ({
  '.ways-to-add-reports-container > .rfrsh-btn': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }
  },
}));

export function Lists() {
  const { data: lists, isLoading } = useAccountListsV2();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Report Lists - PeopleSmart';
  }, []);

  const redirectToSearchContactsPage = () => {
    navigate('/search/contact');
  }

  return (
    <ListsContainer>
      <AccountListsV2 
        lists={lists || []} 
        isLoading={isLoading} 
        customRedirectPath={redirectToSearchContactsPage}
        customClassName='ways-to-add-reports-container'
      />
    </ListsContainer>
  );
}
