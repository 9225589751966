import {
  MonitoringOverview,
  useSriracha,
} from '@ltvco/refresh-lib/v1';

export function Monitoring() {
  const { data } = useSriracha();
  const notifications = data || [];

  document.title = 'Monitoring - PeopleSmart';

  return (
    <MonitoringOverview
      notifications={notifications}
    />
  );
}
