import { useContext, useEffect, useState } from 'react';
import { isNeighborSpoofed, isZeroed, unformatPhone, formatPhone,  useScrollToSectionOnNavigate, DateUtil, } from '@ltvco/refresh-lib/utils';
import {Grid} from '@ltvco/refresh-lib/theme'
import {
  useReport,
  useSession,
  usePhoneSearchData,
  PhoneOverview,
  PotentialOwners,
  EmailSection,
  Education,
  JobSection,
  SocialMediaSection,
  SearchVolume,
  NuisanceCaller,
  CommentsSection,
  DesktopNav,
  ReportLoading,
  ReportActionsTopBar,
  ReportNavMobile,
  ReportOptions,
  PossiblePhotosSection,
  PhoneSection,
  AddressHistory,
  ReportFactory,
  RelatedReportsSection,
  ReportRating,
  NeighborSpoofing,
  ReportNullState,
  NotesSection,
  commonTypes,
  ReportMonitor,
  useReportMonitors,
  Monitoring,
  useRemouladeReportSnapshot,
  ReportChangesOverview,
  ReportClaiming,
  DebugMenu,
  PhoneFraudScanSection,
  PhoneReport as PhoneReportType,
} from '@ltvco/refresh-lib/v1';
import type { ReportRouteProps } from './types';
import { getPhoneNavLinkData } from 'navLinkData/phoneNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import { RatsContainer } from './components';
import questionableCallerGifUrl from '../../images/caller_questionable.gif';
import telemarketerCallerGifUrl from '../../images/caller_telemarketer.gif';
import legitCallerGifUrl from '../../images/caller_legit.gif';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface PhoneReportProps extends ReportRouteProps {}

const reportType = 'phone';

export function PhoneReportPage({
  permalink,
  isMonitored = false,
}: PhoneReportProps) {
  const {
    session: { account },
  } = useSession();
  const { logError } = useContext(AppConfig);

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: false,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [neighborSpoofed, setIsNeighborSpoofed] = useState(false);
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);
  const { data: phoneSearchData } = usePhoneSearchData(
    logError,
    queryResult?.data?.meta?.search_data?.phone_number
  );

  useEffect(() => {
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const [reportUpdateDate, setUpdateDate] = useState<string | undefined>();
  const [report, setReport] = useState<PhoneReportType | null>(null);
  const date = new DateUtil();

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      // The format of queryResult from REMO has an extra data key.
      if (queryResult?.data?.entities)
        setReport(ReportFactory.create(queryResult?.data, 'phone'));
      else setReport(ReportFactory.create(queryResult?.data.data, 'phone'));
    }
  }, [queryResult.data, queryResult.isSuccess, reportOptions]);

  useEffect(() => {
    const remoUpdateDate = currentMonitors?.find(
      (monitor) => monitor.permalink === permalink
    )?.updated_at;
    const coreUpdateDate = report?.data?.meta?.updated_at;

    if (reportIsMonitored && remoUpdateDate)
      setUpdateDate(date.parseDateFromString(remoUpdateDate, 'yyyy-MM-dd'));
    else if (coreUpdateDate)
      setUpdateDate(
        date.parseDateFromString(
          coreUpdateDate,
          'yyyy-MM-dd',
          'yyyy-MM-dd HH:mm:ss ZZZ'
        )
      );

    if (report) {
      const spoofed = isNeighborSpoofed(
        report?.data,
        account?.account?.user_info.phone_number
      );
      setIsNeighborSpoofed(spoofed);
    }
  }, [
    report?.data?.meta?.updated_at,
    currentMonitors,
    permalink,
    reportIsMonitored,
  ]);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={15} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const showReportClaiming =
    unformatPhone(currentUserInfo?.phone_number || '') === report?.phone;

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  if (!report) return <ReportLoading menuItems={15} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);

  const {
    ownerName,
    emails,
    educations,
    jobs,
    profiles,
    usernames,
    images,
    phones,
    addresses,
    relatives,
  } = owner;

  const { risk, connectivity, is_valid } = report.supportingPhone || {};
  const {
    fraud_score,
    is_prepaid,
    is_voip,
    is_risky,
    is_recent_abuse,
    is_do_not_call,
  } = risk || {};

  const is_active = connectivity?.is_active;

  const showPhoneFraudScan =
    report.supportingPhone != null && risk != null && connectivity != null;

  const queryParams = new URLSearchParams(search);
  const fallbackPhone = queryParams.get('phone') || '';
  const phoneNumber = formatPhone(report.phone) ?? formatPhone(fallbackPhone);

  const otherPhones = phones.filter(
    (phone: commonTypes.Phone) => phone.number !== fallbackPhone
  );

  const { main_line_type, num_searches, spam_score } = report.data;
  const searchCount = phoneSearchData?.total_volume || num_searches || 0;
  const comments = report.data.comments || [];
  const carrier = report.data.meta?.search_data?.carrier;
  const peopleCount = report.data.meta?.counts?.people ?? 0;

  const phoneNavLinkData = getPhoneNavLinkData(owner, {
    potentialOwnersLength: report?.data?.people?.length ?? 0,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
  });

  document.title = `${formatPhone(phoneNumber)} - PeopleSmart`;

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <DesktopNav
            navLinkData={phoneNavLinkData}
            reportDataPoint={formatPhone(phoneNumber)}
            permalink={permalink}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} marginTop={3}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={phoneNavLinkData}
            />
          )}
          <RatsContainer>
            <ReportActionsTopBar
              reportPermaLink={permalink}
              reportTitle="Phone"
              reportType={reportType}
              deleteCurrentReport={(permalink: string) => permalink}
            />
          </RatsContainer>
          <PhoneOverview
            reportType={reportType}
            phone={formatPhone(phoneNumber)}
            personName={ownerName}
            neighborSpoofed={neighborSpoofed}
            phoneOverviewItemsData={{
              comments,
              mainLineType: main_line_type,
              numSearches: searchCount,
              spamScore: spam_score,
              carrier,
              peopleCount,
            }}
            Monitoring={MonitoringComponent}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({ showHighConfidenceDataOnly: value });
            }}
          />
          <PhoneFraudScanSection
            phoneNumber={phoneNumber}
            fraudScore={fraud_score}
            validStatus={is_valid}
            prepaid={is_prepaid}
            voip={is_voip}
            risky={is_risky}
            recentAbuse={is_recent_abuse}
            active={is_active}
            doNotCall={is_do_not_call}
            showPhoneFraudScan={showPhoneFraudScan}
          />
          <PotentialOwners
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || report.data.formatted_number}
            permalink={permalink}
          />
          <PossiblePhotosSection
            personName={ownerName || report.data.formatted_number}
            images={images || []}
          />
          <PhoneSection
            phonesCount={otherPhones.length}
            personName={ownerName || report.data.formatted_number}
            phoneList={otherPhones}
            permalink={permalink}
            alternateTitle="Other Phone Numbers"
          />
          <EmailSection
            emailsCount={emails.length}
            personName={ownerName || report.data.formatted_number}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistory
            addresses={addresses}
            addressCount={addresses.length}
            personName={ownerName || report.data.formatted_number}
            permalink={permalink}
            showCityAndStateAddress={true}
          />
          <JobSection
            personName={ownerName || report.data.formatted_number}
            jobs={jobs}
            permalink={permalink}
          />
          <Education
            personName={ownerName || report.data.formatted_number}
            educations={educations}
            permalink={permalink}
          />
          <SocialMediaSection
            personName={ownerName || report.data.formatted_number}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <NeighborSpoofing phoneReportData={report.data} />
          <SearchVolume searchCount={searchCount} />
          <NuisanceCaller
            reportData={report.data}
            callerSourceMap={{
              telemarketer: telemarketerCallerGifUrl,
              questionable: questionableCallerGifUrl,
              legit: legitCallerGifUrl,
            }}
          />
          <CommentsSection reportData={report.data} permalink={permalink} />
          <NotesSection permalink={permalink} />
          <RelatedReportsSection
            reportTitle={phoneNumber}
            reportType={reportType}
            reportOwner={ownerName}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
            hasDefaultContactSearch={true}
          />
          <ReportRating
            permalink={permalink}
            rating={report.data.meta?.rating || null}
          />
        </Grid>
      </Grid>
      <ReportNavMobile navLinkData={phoneNavLinkData} />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
