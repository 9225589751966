import { Owner } from '@ltvco/refresh-lib/v1';

export const getEmailNavLinkData = (
  potentialOwnersLength: number,
  owner: Owner
) => {
  return [
    {
      href: '#email-overview',
      sectionId: '#email-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
      name: 'email-overview',
    },
    {
      href: '#email-fraud-check-section',
      sectionId: '#email-fraud-check-section"',
      text: 'FraudScan Summary',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'fraud_check',
      name: 'email-fraud-check-section',
    },
    {
      href: '#potential-owners-section',
      sectionId: '#potential-owners-section',
      text: 'Potential Owners',
      reportItemsCount: potentialOwnersLength,
      notificationSection: 'owners',
      name: 'potential-owners-section',
    },
    {
      href: '#possible-photos-section',
      sectionId: '#possible-photos-section',
      text: 'Possible Photos',
      reportItemsCount: owner.images?.length || null,
      notificationSection: 'photos',
      name: 'possible-photos-section',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones.length,
      notificationSection: 'phones',
      name: 'phone-numbers-section',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Emails',
      reportItemsCount: owner.emails.length,
      notificationSection: 'emails',
      name: 'email-section',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: owner.addresses.length,
      notificationSection: 'addresses',
      name: 'address-history-section',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Professional Background',
      reportItemsCount: owner.jobs.length,
      notificationSection: 'jobs',
      name: 'jobs-section',
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations.length,
      notificationSection: 'educations',
      name: 'education-section',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social & Websites',
      reportItemsCount: owner.profiles.length + owner.usernames.length,
      notificationSection: 'socials',
      name: 'social-media-section',
    },
  ];
};
