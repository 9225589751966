import { Owner } from '@ltvco/refresh-lib/v1';

export const getSocialNavLinkData = (
  owner: Owner,
  potentialOwnersLength: number
) => {
  return [
    {
      href: '#username-overview',
      sectionId: '#username-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      name: 'username-overview',
    },
    {
      href: '#potential-owners-section',
      sectionId: '#potential-owners-section',
      text: 'Potential Owners',
      reportItemsCount: potentialOwnersLength,
      name: 'potential-owners-section',
    },
    {
      href: '#possible-photos-section',
      sectionId: '#possible-photos-section',
      text: 'Possible Photos',
      reportItemsCount: null,
      name: 'possible-photos-section',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-sectionn',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones?.length || 0,
      name: 'phone-numbers-section',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Emails',
      reportItemsCount: owner.emails?.length || 0,
      name: 'email-section',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: owner.addresses?.length || 0,
      name: 'address-history-section',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Professional Background',
      reportItemsCount: owner.jobs?.length || 0,
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations?.length || 0,
      name: 'education-section',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social & Websites',
      reportItemsCount: owner.profiles.length + owner.usernames.length || 0,
      name: 'social-media-section',
    },
  ];
};
