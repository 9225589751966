import {
  QueryParams,
  FilterTypeOption,
  ReportType,
  ReportFilter,
  SortBy,
  SortDirection,
  SearchComponent,
  FiltersComponent,
  SortComponent,
} from '@ltvco/refresh-lib/v2';
import { Box, Stack, styled } from '@ltvco/refresh-lib/theme';

const SearchParentContainer = styled(Box)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
}));

type SearchAndFilterControlsProps = {
  queryParams: QueryParams;
  setQueryParams: any;
  reportTypeFilterOptions: { [key: string]: FilterTypeOption };
  filterTypes: ReportFilter[];
  setFilterTypes: (reportTypes: ReportFilter[]) => void;
  sort_by: SortBy;
  sort_direction: SortDirection;
};

export function SearchAndFilterControls({
  queryParams,
  setQueryParams,
  reportTypeFilterOptions,
  filterTypes,
  setFilterTypes,
  sort_by,
  sort_direction,
}: SearchAndFilterControlsProps) {
  return (
    <Stack direction={{ xl: 'row', xs: 'column' }} spacing={1.5}>
      <SearchParentContainer>
        <SearchComponent
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      </SearchParentContainer>
      <Stack direction="row" spacing={1.5}>
        <FiltersComponent
          reportTypeOptions={reportTypeFilterOptions}
          reportTypes={filterTypes}
          setReportTypes={setFilterTypes}
        />
        <SortComponent
          currentSort={{ sort_by, sort_direction }}
          setQueryParams={setQueryParams}
        />
      </Stack>
    </Stack>
  );
}
