import { Box, styled, Text, Stack, Link } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';

export const StyledLink = styled(Link)(({ theme }) => ({
  color: `${theme.palette.labelsAndLinks?.main || theme.palette.success.main}`,
  lineHeight: 1.2,
  textDecoration: 'underline',
  '&:hover': {
    color: `${theme.palette.labelsAndLinks?.dark || 'initial'}`,
  },
  fontWeight: 'bold',
}));


export const CustomDisclaimer = () => {
  const { 
    links: { fcraLink, tosLink },
    data: { brandName },
  } = constants;

  return (
    <Stack data-cy="footer_disclaimer" spacing={1.2}>
    <Text variant="body2">
      The information contained in any report and on our website is not
      necessarily 100% accurate, complete or up to date, nor a substitute
      for your own due diligence. Our data comes from a wide variety of sources, 
      but some municipalities and jurisdictions are slow to report and digitize 
      their data, so we cannot guarantee or warrant full accuracy of ALL search results.
    </Text>
    <Text variant="body2">
      {brandName}'s mission is to give people easy and affordable access
      to public record information, but {brandName} does not provide
      private investigator services or consumer reports, and is not a
      consumer reporting agency per the{' '}
      <StyledLink href={fcraLink} underline="none">
        Fair Credit Reporting Act.
      </StyledLink>{' '}
      You may not use our site or service or the information provided to
      make decisions about employment, admission, consumer credit,
      insurance, tenant screening or any other purpose that would require
      FCRA compliance. For more information governing permitted and
      prohibited uses, please review our{' '}
      <StyledLink href={tosLink} underline="none">
        Terms & Conditions.
      </StyledLink>{' '}
    </Text>
  </Stack>
  );
};
