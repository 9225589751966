import { styled, Grid, Link as Anchor } from '@ltvco/refresh-lib/theme';

const ABOUT_US_LINK = 'https://www.peoplesmart.com/about-us/';
const CAREERS_LINK = 'https://www.ltvco.com/careers/';
const REQUEST_CUSTOM_PLAN_LINK = 'https://forms.gle/DQRkuCEfgFBL3Fqf7';

const linkStyles = {
  color: 'primary',
  lineHeight: 1.2,
  textDecoration: 'underline',
  '&:hover': {
    color: 'initial',
  },
  fontWeight: 'bold',
};

const StyledAnchor = styled(Anchor)(() => linkStyles);

export const AboutUsLinks = () => (
  <>
    <Grid item xs={12} sm={3}>
      <StyledAnchor href={ABOUT_US_LINK} target="_blank">
        About PeopleSmart
      </StyledAnchor>
    </Grid>
    <Grid item xs={12} sm={3}>
      <StyledAnchor href={CAREERS_LINK} target="_blank">
        Careers
      </StyledAnchor>
    </Grid>
    <Grid item xs={12} sm={3}>
      <StyledAnchor href={REQUEST_CUSTOM_PLAN_LINK} target="_blank">
        Need More Reports?
      </StyledAnchor>
    </Grid>
  </>
);
