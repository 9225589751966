import { Owner, type NavLinkData } from '@ltvco/refresh-lib/v1';

export const getContactNavLinkData = (
  owner: Owner,
  contactsByCompanyLength: number,
  relatedByCompanyFetched?: boolean,
): NavLinkData[] => {
  const filteredAddresses = owner.addresses
  ? owner.addresses.filter((address) => address.parsed !== null)
  : [];

  const maxDisplayedKeyContactsCount = 10;
  const keyContactsCount = Math.min(maxDisplayedKeyContactsCount, contactsByCompanyLength);
  return [
    {
      href: '#contact-overview',
      sectionId: '#contact-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Email Addresses',
      reportItemsCount: owner.emails.length,
      notificationSection: 'emails',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Phone Numbers',
      reportItemsCount: owner.phones.length,
      notificationSection: 'phones',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social Media',
      reportItemsCount: owner.profiles.length,
      notificationSection: 'socials',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Employment History',
      reportItemsCount: owner.jobs.length,
      notificationSection: 'jobs',
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Education',
      reportItemsCount: owner.educations.length,
      notificationSection: 'educations',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Possible Address History',
      reportItemsCount: filteredAddresses.length,
      notificationSection: 'addresses',
    },
    {
      href: '#key-contacts-section',
      sectionId: '#key-contacts-section',
      text: 'Key Contacts',
      reportItemsCount: keyContactsCount,
      hideReportItemsCount: !relatedByCompanyFetched,
    },
  ];
};
