import { Box, CircularProgress, Text } from "@ltvco/refresh-lib/theme"
import { ContactPageRounded } from "@mui/icons-material"
import { theme } from "theme"


const SmartSearchLoader = () => {
    return (
        <Box sx={{ width: '100%', height: { xs: '100%', md: '25%' }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress size={148} thickness={2} />
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ContactPageRounded sx={{ color: theme.palette.primary.main, fontSize: '35px' }} />
                </Box>
            </Box>
            <Text sx={{ marginTop: { xs: '16px', md: '32px' }, fontWeight: '700' }}>Loading...</Text>
        </Box>
    )
}

export default SmartSearchLoader