import { Link as RRDLink } from 'react-router-dom';
import { styled, Button } from '@ltvco/refresh-lib/theme';

const linkStyles = {
  lineHeight: 1.2,
  textDecoration: 'underline',
  '&:hover': {
    color: 'initial',
  },
  fontWeight: 'bold',
}

export const StyledLink = styled(RRDLink)(({ theme }) => ({
  ...linkStyles,
  color: theme.palette.primary.main,
}));

export const StyledLinkAction = styled(Button)(() => ({
  ...linkStyles,
  margin: 0,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'initial',
    textDecoration: 'underline',
  },
}));
