import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import {
  Pagination,
  Stack,
  Text,
  Divider
} from '@ltvco/refresh-lib/theme';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import {
  LoadingReports,
  SearchAndFilterControls,
  RecentReportsCardDescription,
  NullState,
} from './components';
import { DeleteAllButton, DeleteModal, generateQueryConfig, RecentReportSerialized, ReportList, ReportType, stringifyParams, useRecentReports, useReportDeleteAll } from '@ltvco/refresh-lib/v2';
import { hasRunReports } from './utils';
import { useSession } from '@ltvco/refresh-lib/v1';
import { HistoryRounded } from '@mui/icons-material';
import { pluralizeString, useScreenSize } from '@ltvco/refresh-lib/utils';

const queryConfig = generateQueryConfig();

export function RecentReportsPage() {
  const {
    config: { reportTypeFilterOptions },
  } = useContext(AppConstants);

  const { session } = useSession();

  type ReportFilter = ReportType | 'monitored_report';

  const { isMobile } = useScreenSize();
  const [queryParams, setQueryParams] = useQueryParams(queryConfig);
  const filteredParams = stringifyParams(queryParams);
  const { data: queryData, isLoading } = useRecentReports(filteredParams);
  const [filterTypes, setFilterTypes] = useState<ReportFilter[]>(
    queryParams.report_type || []
  );
  const reportTypes: ReportType[] = filterTypes.filter(
    (type) => type !== 'monitored_report'
  );

  const totalReportsRef = useRef<number | null>(null);
  const totalReports = useMemo(() => {
    if (isLoading && totalReportsRef.current !== null) {
      return totalReportsRef.current;
    }

    return queryData?.meta?.report_quantities?.total_reports_count || 0;
  }, [queryData, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      totalReportsRef.current = totalReports;
    }
  }, [totalReports, isLoading]);

  const totalFilteredReports =
    queryData?.meta?.report_quantities?.filtered_reports_count || 0;
  const pageCount = Math.ceil(totalFilteredReports / 20);
  const reports = (queryData?.reports as RecentReportSerialized[]) || [];

  const [deleteAllReportsModalOpen, setDeleteAllReportsModalOpen] =
    useState(false);

  const [currentReports, setCurrentReports] = useState(reports);

  const { sort_by, sort_direction } = queryParams;

  const hasZeroReports = totalReports === 0;
  useEffect(
    () =>
      setQueryParams({
        report_type: reportTypes,
        page: 1,
      }),
    [
      setQueryParams,
      reportTypes,
      filterTypes,
      hasZeroReports,
      queryParams.search_by,
      queryParams.sort_by,
      queryParams.sort_direction,
    ]
  );

  const deleteAllMutation = useReportDeleteAll(setCurrentReports);
  const deleteAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteAllMutation.mutate();
    setDeleteAllReportsModalOpen(false);
  };

  const handleGoBack = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDeleteAllReportsModalOpen(false);
  };

  const deleteCurrentReport = (permalink: string) =>
    setCurrentReports(
      currentReports.filter(
        (report: RecentReportSerialized) => report.id !== permalink
      )
    );

  function handlePageChange(page: number) {
    setQueryParams({ page });
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    setCurrentReports(reports);
  }, [reports]);

  useEffect(() => {
    document.title = 'My Contacts - PeopleSmart';
  }, []);

  if (!isLoading && reports.length === 0 && !hasRunReports({ session })) {
    return (
      <Stack spacing={3.5}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HistoryRounded sx={{ fontSize: '1.25rem' }} />
          <Text variant="h3">Recent Reports</Text>
        </Stack>
        <NullState />
      </Stack>
    );
  }

  const iconStyles = {
    backgroundColor: 'info.main',
  };

  return (
    <>
      <Stack spacing={3.5} padding={2.5} paddingTop={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HistoryRounded sx={{ fontSize: '1.25rem' }} />
          <Text variant="h3">Recent Reports</Text>
        </Stack>
        <SearchAndFilterControls
          filterTypes={filterTypes}
          setFilterTypes={(filterTypes) => {
            setFilterTypes(filterTypes);
          }}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          reportTypeFilterOptions={reportTypeFilterOptions}
          sort_by={sort_by}
          sort_direction={sort_direction}
        />
        <Stack spacing={1.5} direction="row">
          <Text variant="caption">
            {currentReports.length}{' '}
            {pluralizeString('report', currentReports.length)} viewed
          </Text>
          <Divider
            sx={{ borderColor: 'text.primary' }}
            orientation="vertical"
            flexItem
          />
          <DeleteAllButton
            setDeleteAllReportsModalOpen={setDeleteAllReportsModalOpen}
          />
        </Stack>
        {isLoading ? (
          <LoadingReports />
        ) : (
          <ReportList
            reportList={currentReports}
            deleteCurrentReport={deleteCurrentReport}
            RecentReportsCardDescription={RecentReportsCardDescription}
            iconStyles={iconStyles}
            stackInMobile={isMobile}
          />
        )}
        {!isLoading && pageCount > 1 && (
          <Stack
            spacing={2}
            direction={'row'}
            sx={{ justifyContent: 'center' }}
          >
            <Pagination
              page={queryParams.page}
              count={pageCount}
              onChange={(_: any, page: number) => handlePageChange(page)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-page, & .MuiPaginationItem-ellipsis': {
                  display: 'none',
                },
              }}
            />
          </Stack>
        )}
        <DeleteModal
					isOpen={deleteAllReportsModalOpen}
					setIsOpen={handleGoBack}
					deleteMutation={deleteAll}
					title={'Delete all reports confirmation'}
					confirmationText="Are you sure you want to delete ALL of your reports?"
					deleteText="Delete all"
				/>
      </Stack>
    </>
  );
}
