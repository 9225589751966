import { Owner } from '@ltvco/refresh-lib/v1';

type DataCounts = {
  potentialOwnersLength: number;
  searchVolumeLength: number;
  commentsLength: number;
  neighborSpoofingLength: number;
};

export const getPhoneNavLinkData = (owner: Owner, dataCounts: DataCounts) => {
  const assetsCount =
    owner.ownedAssets['real_estate'].length +
    owner.ownedAssets['vehicles'].aircraft.length +
    owner.ownedAssets['vehicles'].watercraft.length +
    owner.ownedAssets['vehicles'].automobiles.length;

  return [
    {
      href: '#phone-overview',
      sectionId: '#phone-overview',
      text: 'Overview',
      reportItemsCount: null,
      hideReportItemsCount: true,
      notificationSection: 'identity',
      name: 'phone-overview',
    },
    {
      href: '#phone-fraudscan-section',
      sectionId: '#phone-fraudscan-section',
      text: 'FraudScan Summary',
      reportItemsCount: null,
      hideReportItemsCount: true,
      name: 'phone-fraudscan-section',
    },
    {
      href: '#potential-owners-section',
      sectionId: '#potential-owners-section',
      text: 'Potential Owners',
      reportItemsCount: dataCounts.potentialOwnersLength,
      notificationSection: 'owners',
      name: 'potential-owners-section',
    },
    {
      href: '#possible-photos-section',
      sectionId: '#possible-photos-section',
      text: 'Possible Photos',
      reportItemsCount: owner.images?.length || null,
      notificationSection: 'photos',
      name: 'possible-photos-section',
    },
    {
      href: '#phone-numbers-section',
      sectionId: '#phone-numbers-section',
      text: 'Other Phone Numbers',
      reportItemsCount: Math.max(owner.phones.length - 1, 0),
      notificationSection: 'phones',
      name: 'phone-numbers-section',
    },
    {
      href: '#email-section',
      sectionId: '#email-section',
      text: 'Email Addresses',
      reportItemsCount: owner.emails.length,
      notificationSection: 'emails',
      name: 'email-section',
    },
    {
      href: '#address-history-section',
      sectionId: '#address-history-section',
      text: 'Address History',
      reportItemsCount: owner.addresses.length,
      notificationSection: 'addresses',
      name: 'address-history-section',
    },
    {
      href: '#jobs-section',
      sectionId: '#jobs-section',
      text: 'Work History',
      reportItemsCount: owner.jobs.length,
      notificationSection: 'jobs',
      name: 'jobs-section',
    },
    {
      href: '#education-section',
      sectionId: '#education-section',
      text: 'Educational Background',
      reportItemsCount: owner.educations.length,
      notificationSection: 'educations',
      name: 'education-section',
    },
    {
      href: '#social-media-section',
      sectionId: '#social-media-section',
      text: 'Social Media',
      reportItemsCount: owner.profiles.length + owner.usernames.length,
      notificationSection: 'socials',
      name: 'social-media-section',
    },
    {
      href: '#neighbor-spoofing-section',
      sectionId: '#neighbor-spoofing-section',
      text: 'Neighbor Spoofing',
      reportItemsCount: dataCounts.neighborSpoofingLength,
      notificationSection: 'neighbors',
      name: 'neighbor-spoofing-section',
    },
    {
      href: '#search-volume-section',
      sectionId: '#search-volume-section',
      text: 'Search Volume',
      reportItemsCount: dataCounts.searchVolumeLength,
      name: 'search-volume-section',
    },
    {
      href: '#nuisance-caller-section',
      sectionId: '#nuisance-caller-section',
      text: 'Nuisance Caller',
      reportItemsCount: 1,
      name: 'nuisance-caller-section',
    },
    {
      href: '#comments-section',
      sectionId: '#comments-section',
      text: 'Comments',
      reportItemsCount: dataCounts.commentsLength,
      name: 'comments-section',
    },
  ];
};
