import { useEffect } from 'react';
import {
  useReport,
  PotentialOwners,
  EmailSection,
  PhoneSection,
  EmailOverview,
  Education,
  JobSection,
  SocialMediaSection,
  AddressHistory,
  DesktopNav,
  PossiblePhotosSection,
  ReportOptions,
  ReportActionsTopBar,
  ReportLoading,
  ReportRating,
  NotesSection,
  ReportNavMobile,
  ReportFactory,
  RelatedReportsSection,
  ReportNullState,
  Monitoring,
  ReportMonitor,
  useReportMonitors,
  useRemouladeReportSnapshot,
  ReportChangesOverview,
  ReportClaiming,
  useSession,
  DebugMenu,
  EmailFraudCheckSection,
  EmailReport as EmailReportType,
} from '@ltvco/refresh-lib/v1';
import { Grid } from '@ltvco/refresh-lib/theme';
import {  DateUtil, isZeroed, useScrollToSectionOnNavigate,} from '@ltvco/refresh-lib/utils'
import type { ReportRouteProps } from './types';
import { getEmailNavLinkData } from 'navLinkData/emailNavLinkData';
import { useState } from 'react';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import { RatsContainer } from './components';

interface EmailReportProps extends ReportRouteProps {}

const reportType = 'email';

export function EmailReportPage({
  permalink,
  isMonitored = false,
}: EmailReportProps) {
  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const [initialIsMonitored, setInitialIsMonitored] = useState(isMonitored);
  const coreResult = useReport(permalink, initialIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(
    permalink,
    initialIsMonitored
  );
  const {
    session: { account },
  } = useSession();

  const currentUserInfo = account?.account?.user_info;
  const queryResult = (
    initialIsMonitored ? remouladeResult : coreResult
  ) as typeof coreResult;

  // If remoulade fails, we fall back to core. Most likely cause is race condition between creating monitor
  // and remoulade fetching data from core.
  useEffect(() => {
    if (remouladeResult.isError) {
      setInitialIsMonitored(false);
    }
  }, [remouladeResult.isError]);

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  const { currentMonitors, reportIsMonitored } = useReportMonitors();
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);

  useEffect(() => {
    setActiveClaiming(
      currentMonitors.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const [reportUpdateDate, setUpdateDate] = useState<string | undefined>();
  const [report, setReport] = useState<EmailReportType | null>(null);
  const date = new DateUtil();

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      // The format of queryResult from REMO has an extra data key.
      if (queryResult?.data?.entities)
        setReport(ReportFactory.create(queryResult?.data, 'email'));
      else setReport(ReportFactory.create(queryResult?.data.data, 'email'));
    }
  }, [queryResult.data, queryResult.isSuccess, reportOptions]);

  useEffect(() => {
    const remoUpdateDate = currentMonitors?.find(
      (monitor) => monitor.permalink === permalink
    )?.updated_at;
    const coreUpdateDate = report?.data?.meta?.updated_at;

    if (reportIsMonitored && remoUpdateDate)
      setUpdateDate(date.parseDateFromString(remoUpdateDate, 'yyyy-MM-dd'));
    else if (coreUpdateDate)
      setUpdateDate(
        date.parseDateFromString(
          coreUpdateDate,
          'yyyy-MM-dd',
          'yyyy-MM-dd HH:mm:ss ZZZ'
        )
      );
  }, [
    report?.data?.meta?.updated_at,
    currentMonitors,
    permalink,
    reportIsMonitored,
  ]);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={10} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report) return <ReportLoading menuItems={10} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const showReportClaiming =
    currentUserInfo?.email?.toLowerCase() ===
    report.emailAddress?.toLowerCase();

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    images,
    userIds,
    relatives,
  } = owner;

  const { supportingEmail } = report;

  const { fraud_score, deliverability, recent_abuse, is_leaked } =
    supportingEmail || {};

  const emailNavLinkData = getEmailNavLinkData(
    report?.data.people?.length || 0,
    owner
  );

  const queryParams = new URLSearchParams(search);
  const fallbackEmail = queryParams.get('email') || '';
  const lowerCaseEmailAddress =
    report?.emailAddress?.toLowerCase() ?? fallbackEmail;
  document.title = `${lowerCaseEmailAddress} - PeopleSmart`;

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <DesktopNav
            navLinkData={emailNavLinkData}
            reportDataPoint={lowerCaseEmailAddress}
            permalink={permalink}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} marginTop={3}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={emailNavLinkData}
            />
          )}
          <RatsContainer>
            <ReportActionsTopBar
              reportPermaLink={permalink}
              reportTitle="Email"
              reportType={reportType}
              deleteCurrentReport={(permalink: string) => permalink}
            />
          </RatsContainer>
          <EmailOverview
            reportType={reportType}
            email={lowerCaseEmailAddress}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({
                showHighConfidenceDataOnly: value,
              });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            emailOverviewItemsData={{
              ownerName,
              emails,
              jobs,
              educations,
              phones,
              profiles,
              usernames,
              userIds,
              peopleCount: report.data.people?.length,
            }}
            Monitoring={MonitoringComponent}
          />
          <EmailFraudCheckSection
            fraudScore={fraud_score}
            deliverability={deliverability}
            abusiveBehavior={recent_abuse}
            leaked={is_leaked}
            showEmailFraudScan={supportingEmail != undefined}
            emailAddress={lowerCaseEmailAddress}
          />
          <PotentialOwners
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />
          <PossiblePhotosSection
            personName={ownerName || lowerCaseEmailAddress}
            images={images || []}
          />
          <PhoneSection
            phonesCount={phones.length}
            personName={ownerName || lowerCaseEmailAddress}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSection
            emailsCount={emails.length}
            personName={ownerName || lowerCaseEmailAddress}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistory
            addresses={addresses}
            addressCount={addresses.length}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
            showCityAndStateAddress={true}
          />
          <JobSection
            personName={ownerName || lowerCaseEmailAddress}
            jobs={jobs}
            permalink={permalink}
          />
          <Education
            personName={ownerName || lowerCaseEmailAddress}
            educations={educations}
            permalink={permalink}
          />
          <SocialMediaSection
            personName={ownerName || lowerCaseEmailAddress}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <NotesSection permalink={permalink} />
          <ReportRating
            permalink={permalink}
            rating={report.data.meta?.rating || null}
          />
          <RelatedReportsSection
            reportTitle={lowerCaseEmailAddress}
            reportType="email"
            reportOwner={ownerName}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
            hasDefaultContactSearch={true}
          />
        </Grid>
      </Grid>
      <ReportNavMobile navLinkData={emailNavLinkData} />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
