export const checkSingleResult = (responseData: object): { hasSingleResult: boolean, reportType?: string, singleSearchQuery?: string } => {
    let singleResultCount = 0;
    let searchQuery = "";
    let reportType = "";

    for (const [key, value] of Object.entries(responseData)) {
        if (Array.isArray(value)) {
            if (value.length == 1) {
                searchQuery = value[0];
                reportType = key.slice(0, -1);
                singleResultCount++;
            }
            else {
                return { hasSingleResult: false };
            }
        }
    }

    if (singleResultCount == 1) {
        return { hasSingleResult: true, reportType: reportType, singleSearchQuery: searchQuery };
    }

    return { hasSingleResult: false };
};