import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import imgDarkWebEmail from './images/img-dark-web-email.svg';
import paymentRequired from './images/img_payment_required.svg';
import googlePay from './images/img_google_pay.svg';
import applePay from './images/img_apple_pay.svg';
import venmo from './images/img_venmo.svg';
import venmoButton from './images/img_venmo_button.svg';
import visa from './images/img_visa.svg';
import american_express from './images/img_amex.svg';
import discover from './images/img_discover.svg';
import genericCreditCard from './images/img_generic_credit_card.svg';
import paypal from './images/img_paypal.svg';
import masterCard from './images/img_mastercard.svg';
import facebookLogo from './images/img_social_facebook.svg';
import facebookTwitter from './images/img_social_twitter.svg';
import imgExampleAircraft from './images/img_example_aircraft.svg';
import imgExampleAutomobile from './images/img_example_automobile.svg';
import imgExampleWatercraft from './images/img_example_watercraft.svg';
import imgMonitoringBell from './images/img_bell_monitoring.svg';
import imgIdHub from './images/id-hub.svg';
import imgAddressGeneric from './images/img_address_generic_no_bg.svg';
import imgBell from './images/img_bell.svg';
import imgDarkWebScan from './images/img_dark_web_scan.svg';
import imgSpecializedBackgroundCheck from './images/specialized-background-check.svg';
import imgSpecializedUnclaimedMoney from './images/specialized-unclaimed-money.svg';
import imgReportRating from './images/img_report_rating.svg';
import imgSuccessfulUpgrade from './images/img_successful_upgrade.svg';
import imgComputerLimitReached from './images/img_computer_limit_reached.svg';
import img24hr from './images/img_24hr.svg';
import img_pdf_glow from './images/img_pdf_glow.svg';
import neighborSpoofing from './images/neighbor-spoofing.svg';
import imgNoProperty from './images/no-property-img-fallback.svg';
import imgFlowerObituary from './images/flowerObituary.svg';
import imgMonitorBanner from './images/img_neighborhood_safety.svg';
import imgAnimatedPersonMagnifying from './images/ani_person_magnifying.gif';
import imgCheckList from './images/icn_img_checklist.svg';
import imgAppStoreBadge from './images/img_apple_app_store_badge.svg';
import imgGooglePlayBadge from './images/img_badge_google_play.svg';
import imgMobileFreemium from './images/img_mobile_freemium.svg';
import imgExpiredEmailLink from './images/img_expired_email_link.svg';
import imgFallbackProperty from './images/img_fallback_property.svg';
import imgMapDefaultPin from './images/img_map_default_pin.png';
import imgMapMainHomePin from './images/img_map_main_home_pin.svg';
import imgMapSecondaryPin from './images/img_map_secondary_pin.svg';
import imgMapBoxPin from './images/img_pin.svg';
import { ReportTypeOption, PillList } from '@ltvco/refresh-lib/v1';
import { CustomIcons, Stack } from '@ltvco/refresh-lib/theme';
import ComplianceLogo from './images/img-alert.svg';
import SearchLogo from './images/search-logo.svg';
import imgCustomerService from './images/img_customer_service.svg';
import imgDefaultContactSearchMessage from './images/default-contact-search-message.svg';
import imgNoContactResultsFound from './images/no-contact-results-found.svg';
import imgContactSearchRightArrow from './images/contact-search-right-arrow.svg';
import imgSingleSignOnCta from './images/img_sso_cta.svg';
import imgFraudCheck from './images/img_fraud_check.svg';
import imgNoticeLeave from './images/img_notice_leave.svg';
import imgMigrationCta from 'images/img_migration_cta.svg';
import { AccountCircle, AlternateEmail, EditNote, Email, Facebook, List, NotificationsNone, Person, Phone, Radar, Restore, SupervisorAccount } from '@mui/icons-material';
import { theme } from 'theme';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const reportTypeFilterOptions: { [key: string]: ReportTypeOption } = {
  social_network_report: {
    id: 'social_network_report',
    display: 'Email',
    eventLabel: 'Email',
  },
  username_report: {
    id: 'username_report',
    display: 'Username',
    eventLabel: 'Username',
  },
  contact_report: {
    id: 'contact_report',
    display: 'Contact Pro',
    eventLabel: 'Contact',
  },
  phone_fraud_report: {
    id: 'phone_fraud_report',
    display: 'Phone Fraud',
    eventLabel: 'Phone Fraud',
  },
  email_fraud_report: {
    id: 'email_fraud_report',
    display: 'Email Fraud',
    eventLabel: 'Email Fraud',
  },
  ip_fraud_report: {
    id: 'ip_fraud_report',
    display: 'IP Fraud',
    eventLabel: 'IP Fraud',
  },
  url_fraud_report: {
    id: 'url_fraud_report',
    display: 'URL Fraud',
    eventLabel: 'Url Fraud',
  },
};

// TODO: should we extend the default config the same way we're doing with the theme? might be good for some props, other props maybe should be required
export const constants = {
  images: {
    astrology: {
      imgAstrologyNumerology: '',
      imgAstrologyNumerologyV2: '',
      imgAstrologyNumerologyLoading: '',
      compatibilityImg: '',
      aries: '',
      taurus: '',
      gemini: '',
      cancer: '',
      leo: '',
      virgo: '',
      libra: '',
      scorpio: '',
      sagittarius: '',
      capricorn: '',
      aquarius: '',
      pisces: '',
      empty: '',
      emptyBackground: '',
      smallAries: '',
      smallTaurus: '',
      smallGemini: '',
      smallCancer: '',
      smallLeo: '',
      smallVirgo: '',
      smallLibra: '',
      smallScorpio: '',
      smallSagittarius: '',
      smallCapricorn: '',
      smallAquarius: '',
      smallPisces: '',
      smallAscendant: '',
    },
    imgClaimReport: '',
    imgClaimedReport: '',
    brandLogo: logo,
    brandLogoMobile: logoSmall,
    imgAppleStore: '',
    imgGoogleStore: '',
    master: masterCard,
    visa: visa,
    american_express: american_express,
    discover: discover,
    genericCreditCard: genericCreditCard,
    paypal: paypal,
    googlePay: googlePay,
    paymentRequired: paymentRequired,
    applePay: applePay,
    venmo: venmo,
    venmoButton: venmoButton,
    searchLogo: SearchLogo,
    complianceLogo: ComplianceLogo,
    imgDarkWebEmail: imgDarkWebEmail,
    imgNoProperty,
    imgExampleAircraft: imgExampleAircraft,
    imgExampleWatercraft: imgExampleWatercraft,
    imgExampleAutomobile: imgExampleAutomobile,
    monitoringBell: imgMonitoringBell,
    imgIdHub,
    imgAddressGeneric,
    imgBell,
    imgFlowerObituary,
    imgDarkWebScan,
    imgSpecializedBackgroundCheck,
    imgSpecializedUnclaimedMoney,
    imgReportRating: imgReportRating,
    imgSuccessfulUpgrade: imgSuccessfulUpgrade,
    imgComputerLimitReached: imgComputerLimitReached,
    pdfLogo: img_pdf_glow,
    img24hr,
    neighborSpoofing,
    imgAnimatedPersonMagnifying,
    imgMagnifyingGlassNoResultsPage: '',
    imgMapDefaultPin: imgMapDefaultPin,
    imgMapMainHomePin: imgMapMainHomePin,
    imgMapSecondaryPin: imgMapSecondaryPin,
    imgMonitorBanner: imgMonitorBanner,
    imgMobileExpIcon: '',
    imgCopyIcon: '',
    imgContactlessIcon: '',
    imgUpdatesIcon: '',
    imgEtagsLogo: '',
    imgAddVehicle: '',
    imgNHFacts: '',
    imgMarketTrends: '',
    imgAmenities: '',
    imgWhatIsAVin: '',
    imgWhereIsVin: '',
    imgCheckList,
    imgAppStoreBadge,
    imgGooglePlayBadge,
    imgMobileFreemium,
    imgCustomerService,
    imgExpiredEmailLink,
    imgFallbackProperty,
    imgNoVehicleImageProvided: '',
    imgMapBoxPin,
    imgDefaultContactSearchMessage,
    imgNoContactResultsFound,
    imgContactSearchRightArrow,
    imgSingleSignOnCta,
    imgFraudCheck,
    imgNoticeLeave,
    imgMigrationCta,
  },
  links: {
    baseUrl: `${baseUrl}${
      import.meta.env.VITE_PATH_PREFIX?.includes('/')
        ? import.meta.env.VITE_PATH_PREFIX
        : `/${import.meta.env.VITE_PATH_PREFIX}`
    }`,
    joinLink: 'https://www.peoplesmart.com/lp/d8937f/4/select-plan',
    brandLink: import.meta.env.VITE_DEV_PROXY_SEARCH_PERSON_TEASER
      ? ''
      : `${baseUrl}`,
    aboutLink: 'https://peoplesmart.com/about',
    fcraLink: 'https://www.peoplesmart.com/faq/privacy/#what-is-the-fcra/',
    doDontsLink: 'https://www.peoplesmart.com/faq/privacy/#dos-and-donts-of-using-peoplesmart',
    tosLink: 'https://www.peoplesmart.com/terms/',
    privacyLink: 'https://www.peoplesmart.com/privacy-policy/',
    contactLink: 'https://www.peoplesmart.com/contact-us/',
    FaqLink: 'https://www.peoplesmart.com/faq/',
    dontSellInfoLink: 'https://www.beenverified.com/app/optout/contact-search',
    affiliateLink: 'https://www.peoplesmart.com/affiliates',
    peopleSearchLink: '',
    reversePhoneLink: 'https://www.peoplesmart.com/phone',
    emailLookupLink: '',
    reverseAddressLink: '',
    contactForm: 'https://www.peoplesmart.com/contact-us/',
    supportLink: 'http://support.peoplesmart.com',
    twitterLink: '',
    facebookLink: '',
    blogLink: '',
    brandIosUrl: 'https://itunes.apple.com/us/app/number-guru/id431750800',
    brandAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.peoplesmart&hl=en_US&gl=US&pli=1',
    rapidsLookupLink: `${baseUrl}/rpds/lookup`,
    mrRogersBaseUrl: '/ss/mrg',
    billingLink: 'https://www.peoplesmart.com/faq/billing',
    customPlanLink: 'https://forms.gle/DQRkuCEfgFBL3Fqf7',
    mapboxStyleUrl: 'mapbox://styles/beenverified/cloywa73e00sd01qnb8162waw',
    dataDeckRootURI: 'hk/dd/v2',
    chatLink: 'https://www.peoplesmart.com/chat/',
    topHapBaseUrl: 'https://widgets.tophap.com/api/widgets',
    setupSsoLink: 'https://www.peoplesmart.com/sso_connections',
  },
  featureFlags: {
    hasCriminalEnabled: true,
    propertyReportFlags: [],
    notificationsDisabled: true,
    navigationHeaderEnabled: true,
    hasContactSearchAsDefaultSearch: true,
    hasAddToContactEnabled: true,
  },
  data: {
    brandName: 'PeopleSmart',
    brandAbbreviation: 'ps' as 'ps',
    brandSupportEmail: 'support@peoplesmart.com',
    legalName: 'peoplesmart LLC',
    supportPhone: '1-866-673-9048',
    customerSupportPhone: '1-888-579-5910',
    supportEmail: 'support@peoplesmart.com',
    complianceEmail: 'compliance@peoplesmart.com',
    pdfPrice: '$4.95',
    rewardsDisclaimer:
      'Certain products and services, including vehicle purchase/sale opportunities, provided above include offers from third parties and/or external partners from whom we may receive limited compensation. Please be advised that these offers are provided as a convenience and are a sample, but do not necessarily include ALL offers, products or services available in the marketplace.',
    upgradePlanCustomSolutionHeading: 'Need more reports or users?',
    upgradePlanCustomSolutionDescription: 'Let’s talk',
    limitReachedSubHeading:
      'We offer different solutions to match your search needs',
    contactOptionsCustomPlanDisplay: 'Request larger plan',
    copyrightText: `© ${new Date().getFullYear()} PeopleSmart LLC. All rights reserved. PeopleSmart® and the PeopleSmart logo are registered trademarks of PeopleSmart LLC`,
  },
  config: {
    socialMedia: [
      {
        textContent: 'Twitter',
        alternateTextContent: '@peoplesmart',
        href: 'https://www.twitter.com/peoplesmart',
        imgLogoUrl: facebookTwitter,
        icon: <CustomIcons.TwitterIcon color="primary" />,
        hideOnSide: false,
      },
      {
        textContent: 'Facebook',
        alternateTextContent: 'facebook.com/peoplesmartcom',
        href: 'https://www.facebook.com/peoplesmartcom',
        imgLogoUrl: facebookLogo,
        icon: <Facebook fontSize="small" sx={{ color: theme.palette.primary.main }} />,
        hideOnSide: false,
      },
    ],
    headerTabs: [
      {
        id: 'contactSearchTab',
        displayName: 'Search',
        route: '/search/contact',
        redirect: true,
        icon: <></>,
      },
      {
        id: 'myContactSearchTab',
        displayName: 'My Contacts',
        route: '/dashboard',
        redirect: true,
        icon: <></>,
      },
      {
        id: 'listSearchTab',
        displayName: 'Lists',
        route: '/dashboard/lists',
        redirect: true,
        icon: <></>,
      },
      {
        id: 'apiSearchTab',
        displayName: 'API',
        route: 'https://forms.gle/VFGoyqpHNFNJMmTf8',
        openInNewTab: true,
        icon: <></>,
      },
    ],
    searchTabs: [
      {
        displayName: 'Phone',
        id: 'phoneTab',
        icon: <Phone fontSize="small" sx={{ color: theme.palette.primary.main }} />,
        class: 'noBorderBottom noBorderRight',
      },
      {
        displayName: 'Email',
        id: 'emailTab',
        icon: <Email fontSize="small" sx={{ color: theme.palette.primary.main }} />,
        class: 'noBorderRight',
      },
      {
        displayName: 'Username',
        id: 'usernameTab',
        icon: <AlternateEmail fontSize="small" sx={{ color: theme.palette.primary.main }} />,
        class: '',
      },
      {
        displayName: 'Name / Job History',
        id: 'contacTab',
        icon: <Person fontSize="small" sx={{ color: theme.palette.primary.main }} />,
        class: 'noBorderBottom noBorderRight',
        route: '/search/contact',
        openInNewTab: true,
      },
    ],
    linkTabs: [
      {
        displayName: 'Search',
        id: 'searchLink',
        class: 'noBorderBottom noBorderRight',
        route: '/search/contact',
      },
      {
        displayName: 'My Contacts',
        id: 'myContactsLink',
        class: 'noBorderBottom noBorderRight',
        route: '/dashboard',
      },
      {
        displayName: 'Lists',
        id: 'listsLink',
        class: 'noBorderBottom noBorderRight',
        route: '/dashboard/report-lists',
      },
      {
        displayName: 'API',
        id: 'apiLink',
        class: 'noBorderBottom noBorderRight',
        route: 'https://forms.gle/VFGoyqpHNFNJMmTf8',
      },
    ],
    sideMenuItems: [
      {
        route: '/search/contact',
        icon: <SupervisorAccount sx={{ color: theme.palette.primary.main }} />,
        text: 'Search Contacts',
        state: '',
        name: 'contactSearch',
      },
      {
        route: '/dashboard',
        icon: <Restore sx={{ color: theme.palette.primary.main }} />,
        text: 'My Contacts',
        name: 'myContacts',
        state: '',
        'data-cy': 'recent_reports',
      },
      {
        route: '/dashboard/lists',
        icon: <List sx={{ color: theme.palette.primary.main }} />,
        text: 'Saved Reports and Lists',
        state: '',
        name: 'lists',
        'data-cy': 'saved_reports_and_list',
      },
      {
        route: '/dashboard/notes',
        icon: <EditNote sx={{ color: theme.palette.primary.main }} />,
        text: 'Notes',
        state: '',
        name: 'notes',
        'data-cy': 'notes',
      },
      {
        route: '/dashboard/fraud',
        icon: <Radar sx={{ color: theme.palette.primary.main }} />,
        text: (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            FraudScan
            <PillList items={[{ label: 'New' }]} />
          </Stack>
        ),
        state: '',
        name: 'fraudScan',
      },
      {
        route: '/dashboard/monitoring',
        icon: <NotificationsNone sx={{ color: theme.palette.primary.main }} />,
        text: 'Monitoring',
        state: '',
        name: 'monitoring',
      },
      {
        route: '/dashboard/billing',
        icon: <AccountCircle sx={{ color: theme.palette.primary.main }} />,
        text: 'My Account',
        state: '',
        name: 'myAccount',
        'data-cy': 'my_account',
      },
    ],
    reportTypeFilterOptions,
    higherConfidenceThreshold: 50,
    hardcodedPlans: [],
  },
  keys: {
    rapidsAPIKey: '2KCMNc4EEFJmMMDbhmru',
    mapboxKey:
      'pk.eyJ1IjoiYmVlbnZlcmlmaWVkIiwiYSI6ImNraDB2NjQxaDBqa2gyd3JzOHZ5YWc4YjUifQ.sb_FZ1nJvTv-eL68-9n2YQ',
    mrRogersAPIKey: 'wQJAKbJ3sGoAwYsGITmGqEFJr2GGdNbL',
    streetViewStaticApiKey: 'AIzaSyA6H5JZT4MeOKS_JUzBmtBC-wCsvDqIm40',
    topHapApiKey: '',
  },
  migrationPlanNames: [
    '22_86_1_month_nofree_afill_limit100reports_freepdf_peoplesmart_plan_migration',
  ],
};
