import {
  AppConfig
} from '@ltvco/refresh-lib/ctx';
import {
  ContactCompany,
  ContactDetail,
  ContactFiltersSidebar,
  ContactHeader,
  ContactResult,
  ContactResultSkeleton,
  ContactSearchParams,
  NoContactResults,
  PillLabel,
  PillList,
  SerializedContactTeaserData,
  contactEmployeeCountRanges,
  contactSeniorityLevels,
  openReportInNewTab,
  removeDefaultAndEmptyContactSearchParams,
  useReportRedirect,
  useSearchContact,
} from '@ltvco/refresh-lib/v1';
import {
  useTheme,
  useMediaQuery,
  Stack,
  Select,
  FormControl,
  Pagination,
  Grid,
  SelectChangeEvent,
  Text,
  Theme,
  Box,
  Button,
  Pill,
} from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SmartSearchModal from 'components/SmartSearch/SmartSearchModal';
import SmartSearchResultCard from 'components/SmartSearch/SmartSearchResultCard';
import SmartSearchLoader from 'components/SmartSearch/SmartSearchLoader';
import { Cancel, Tune } from '@mui/icons-material';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

type Pill = { label: string;[key: string]: unknown };

const LIMIT = '20';
const locationFilters: Pill[] = [
  {
    label: 'New York Metro Area',
    type: 'location',
    city: 'New York',
    state: 'NY',
  },
  { label: 'Dallas', type: 'location', city: 'Dallas', state: 'TX' },
  { label: 'California', type: 'location', city: '', state: 'CA' },
  { label: 'Chicago', type: 'location', city: 'Chicago', state: 'IL' },
  { label: 'Seattle', type: 'location', city: 'Seattle', state: 'WA' },
];
const seniorityFilters: Pill[] = [
  { label: 'CXO (Executive)', type: 'level', value: 'cxo' },
  { label: 'Owner', type: 'level', value: 'owner' },
  { label: 'Partner', type: 'level', value: 'partner' },
  { label: 'VP', type: 'level', value: 'vp' },
  { label: 'Director', type: 'level', value: 'director' },
  { label: 'Manager', type: 'level', value: 'manager' },
];

/**
 * Get active filters from search params and format them to be used in the PillList component
 */
function getFiltersFromSearchParams(
  availableSearchData: ContactSearchParams,
  searchData: ContactSearchParams
): Pill[] {
  const filters: Pill[] = [];

  Object.entries(availableSearchData).forEach(([type, value]) => {
    if (['limit', 'contact_page'].includes(type)) return;

    if (type === 'level') {
      (value as string[]).forEach((level: string) => {
        filters.push({
          label:
            contactSeniorityLevels.find(({ value }) => value === level)
              ?.label || '',
          type,
          value: level,
        });
      });

      return;
    }

    if (['company_size_min', 'company_size_max'].includes(type)) {
      // Don't add company size filter if it's already in the filters
      if (filters.find((filter) => filter.type === 'company_size')) return;

      const min = contactEmployeeCountRanges.find(
        ({ value }) => value.toString() === searchData.company_size_min
      )?.label;
      const max = contactEmployeeCountRanges.find(
        ({ value }) => value.toString() === searchData.company_size_max
      )?.label;

      filters.push({
        label: `${min}-${max} Employees`,
        type: 'company_size',
        value,
      });

      return;
    }

    filters.push({ label: value, type, value });
  });

  return filters;
}

export interface SmartSearchResponse {
  city: string[] | "";
  company: string[] | "";
  emails: string[] | null;
  employee_count: string[] | "";
  industry: string[] | "";
  names: string[] | null;
  phones: string[] | null;
  state: string[] | "";
  title: string[] | "";
  usernames: string[] | null;
}

export function SearchContactPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { routingUtils, trackEvent } = useContext(AppConfig);
  const { redirectToSearchContactUrl } = useReportRedirect();

  const [isFormCollapsed, setIsFormCollapsed] = useState(true);
  const [smartSearchQuery, setSmartSearchQuery] = useState("");
  const [hasSmartSearchResult, setHasSmartSearchResult] = useState(false);
  const [smartSearchData, setSmartSearchData] = useState<SmartSearchResponse>({
    city: "",
    company: "",
    emails: null,
    employee_count: "",
    industry: "",
    names: null,
    phones: null,
    state: "",
    title: "",
    usernames: null
  });
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    document.title = 'Search - PeopleSmart';
  }, []);

  let searchData: ContactSearchParams = {
    name: searchParams.get('name') || '',
    title: searchParams.get('title') || '',
    company: searchParams.get('company') || '',
    city: searchParams.get('city') || '',
    state: searchParams.get('state') || '',
    level: searchParams.getAll('level'),
    industry: searchParams.get('industry') || '',
    company_size_min: searchParams.get('company_size_min') || '1',
    company_size_max: searchParams.get('company_size_max') || '9',
    limit: searchParams.get('limit') || LIMIT,
    contact_page: searchParams.get('contact_page') || '1',
  };
  const availableSearchData =
    removeDefaultAndEmptyContactSearchParams(searchData);

  let {
    data: searchContactResult = [],
    isFetched,
    isError,
  } = useSearchContact({
    contactSearchParams: availableSearchData,
    // TODO: Once the OptOut implementation
    // isOptout: false,
  });
  searchContactResult = searchContactResult as SerializedContactTeaserData;

  const { contacts = [], total } = searchContactResult;
  const hasFilters = Object.keys(availableSearchData).length > 0;
  const isLoading = hasFilters && !isFetched;
  const hasResults = hasFilters && !isError && isFetched && contacts.length > 0;
  const noResultsFound =
    hasFilters && !isError && isFetched && contacts.length === 0;
  const activeFilters = getFiltersFromSearchParams(
    availableSearchData,
    searchData
  );
  const limit = parseInt(searchData.limit ?? LIMIT, 10);
  const page = parseInt(searchData.contact_page ?? '1', 10);

  const { isMobile, isMedium } = useScreenSize();

  const getCompanyUrl = (company: string) =>
    routingUtils.searchContactUrl({ company: company });

  const handleCompanyClick = (company: string | ContactCompany) => {
    if (typeof company === 'string') {
      redirectToSearchContactUrl({ company });

      return;
    }

    redirectToSearchContactUrl({ company: company.name });
  };

  const handleViewReport = (contactId: string) => {
    trackEvent('contact search', 'related contacts_same title', 'search');
    openReportInNewTab(
      {
        contact_id: contactId,
        searchType: 'contact',
      },
      constants.links.baseUrl
    );
  };

  const handlePageChange = (page: number) => {
    setSearchParams((previousParams) => {
      previousParams.set('contact_page', page.toString());

      return previousParams;
    });

    window.scrollTo(0, 0);
  };

  const handleLimitChange = (event: SelectChangeEvent) => {
    setSearchParams((previousParams) => {
      previousParams.set('limit', String(event.target.value));
      previousParams.delete('contact_page');

      return previousParams;
    });
  };

  const handleOnRemoveFilter = (filter: Pill) => {
    setSearchParams((previousParams) => {
      if (filter.type === 'state') {
        previousParams.delete('city');
        previousParams.delete('state');

        return previousParams;
      }

      if (filter.type === 'level') {
        const levels = previousParams.getAll('level');
        const newLevels = levels.filter((level) => level !== filter.value);
        previousParams.delete('level');
        newLevels.forEach((level) => {
          previousParams.append('level', level);
        });

        return previousParams;
      }

      if (filter.type === 'company_size') {
        previousParams.delete('company_size_min');
        previousParams.delete('company_size_max');

        return previousParams;
      }

      previousParams.delete(filter.type as string);

      return previousParams;
    });
  };

  const handleOnSubmit = (data: ContactSearchParams) => {
    delete data.contact_page;
    setHasSmartSearchResult(false);
    redirectToSearchContactUrl(data);
  };

  const handleOnRemoveAllFilters = () => {
    setSearchParams({});
  };

  const clearSmartSearchResult = () => {
    setHasSmartSearchResult(false);
    setSmartSearchQuery("");
  }

  const clearAllFields = () => {
    handleOnRemoveAllFilters();
    clearSmartSearchResult();
  }

  const preventSidebarScrollStyles = {
    height: isMedium ? '93vh' : 'unset',
    overflowY: isMedium ? 'scroll' : 'unset'
  }

  return (
    <Stack sx={{ minHeight: 800, position: 'relative' }}>
      <Grid container spacing={2} sx={{ mt: { xs: -1, md: 3 } }}>
        <Grid item xs={12} md={3}>
          {isMedium && (
            <Box p={3} bgcolor={'white'}>
              <Button
                id="filters-toggle"
                type="button"
                name="filters-toggle"
                variant="outlined"
                onClick={() => setIsFormCollapsed(prev => !prev)}
                fullWidth
                color="success"
              >
                <Text variant="h3" sx={{ mb: 1 }}>
                  <Tune sx={{ m: 0.75, mb: -0.75 }} />
                  {isFormCollapsed ? 'Search & Filter' : 'Close Search & Filter'}
                </Text>
              </Button>
            </Box>
          )}
          <SmartSearchModal
            isFormCollapsed={isFormCollapsed}
            setHasSmartSearchResult={setHasSmartSearchResult}
            setSmartSearchData={setSmartSearchData}
            setLoading={setLoading}
            handleOnRemoveAllFilters={handleOnRemoveAllFilters}
            setCollapseForm={setIsFormCollapsed}
            query={smartSearchQuery}
            setQuery={setSmartSearchQuery}
          />
          <ContactFiltersSidebar
            contactInfo={availableSearchData}
            onSubmit={handleOnSubmit}
            onClearAll={clearAllFields}
            shouldDisplayReportSearch={true}
            makeSearchPinned={true}
            collapseForm={isFormCollapsed}
            shouldHideFiltersToggleBtn={true}
            sidebarScroll={{
              shouldPrevent: true,
              styles: preventSidebarScrollStyles,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          sx={{
            pr: { md: 2 },
            mx: { xs: 2, md: 0 },
          }}
        >
          {((!hasFilters || noResultsFound) && (!hasSmartSearchResult && !loading)) && (
            <NoContactResults
              filters={
                <PillList
                  items={activeFilters}
                  icon={<Cancel fontSize="small" />}
                  onItemClicked={handleOnRemoveFilter}
                />
              }
              suggestedFilters={[
                {
                  header: 'Try searching for contacts by location:',
                  filters: (
                    <PillList
                      items={locationFilters}
                      onItemClicked={(item: Pill) => {
                        let params: { [key: string]: string } = {
                          state: item.state as string,
                        };

                        if (item.city) {
                          params = {
                            ...params,
                            city: item.city as string,
                          };
                        }

                        setSearchParams(params);
                      }}
                    />
                  ),
                },
                {
                  header:
                    'Try searching by seniority to connect with the right decision makers:',
                  filters: (
                    <PillList
                      items={seniorityFilters}
                      onItemClicked={(item: Pill) => {
                        setSearchParams({ level: item.value as string });
                      }}
                    />
                  ),
                },
              ]}
              noResultsFound={noResultsFound}
            />
          )}

          {hasResults && (
            <>
              <PillList
                items={activeFilters}
                icon={<Cancel fontSize="small" />}
                onItemClicked={handleOnRemoveFilter}
                onRemoveAll={handleOnRemoveAllFilters}
                sx={{ mb: 2 }}
              />
              {!isMobile && (
                <ContactHeader
                  cardBackgroundColor={theme.palette.primary.dark}
                  textWidth="30%"
                  gridSpacing={20}
                />
              )}
              <Stack spacing={2}>
                {contacts.map((result: ContactDetail) => (
                  <ContactResult
                    key={result.id}
                    {...result}
                    nameMatch={
                      result.name.toLowerCase() ===
                      (availableSearchData?.name || '').toLowerCase()
                    }
                    companyToMatch={availableSearchData?.company || ''}
                    onCompanyClick={handleCompanyClick}
                    getCompanyUrl={getCompanyUrl}
                    onViewReport={handleViewReport}
                  />
                ))}
              </Stack>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={3}
                py={3}
                justifyContent="space-between"
                alignItems="center"
              >
                <Pagination
                  count={total / limit}
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  size={isSm ? 'large' : 'medium'}
                  showFirstButton={isLg}
                  showLastButton={isLg}
                  sx={(theme: Theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      '& .MuiPaginationItem-ellipsis': {
                        margin: 0,
                        padding: 0,
                        minWidth: 12,
                      },
                    },
                  })}
                  onChange={(_: any, page: number) => handlePageChange(page)}
                />
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <FormControl size="small" margin="none">
                    <Select
                      id="page-size-select"
                      value={searchData.limit}
                      onChange={handleLimitChange}
                      options={[
                        { value: LIMIT, display: LIMIT },
                        { value: '50', display: '50' },
                        { value: '100', display: '100' },
                      ]}
                      autoWidth
                    />
                  </FormControl>
                  <Text>results per page</Text>
                </Stack>
              </Stack>
            </>
          )}

          {loading && <SmartSearchLoader />}

          {hasSmartSearchResult && (
            <>
              <Pill
                label={
                  <PillLabel
                    label="Clear All"
                    icon={<Cancel fontSize="small" />}
                  />
                }
                color="primary"
                onClick={clearSmartSearchResult}
                size="small"
                sx={(theme) => ({
                  padding: theme.spacing(0.4),
                  mb: 2
                })}
              />
              <Text sx={{ marginBottom: '20px', fontWeight: '700', fontSize: '1.25rem' }}>Smart Search Matches</Text>
              <Stack spacing={2}>
                {Object.keys(smartSearchData).map((key) => {
                  const value = smartSearchData[key as keyof SmartSearchResponse];

                  if (Array.isArray(value) && value.length > 0) {
                    return value.map((item) => (
                      <SmartSearchResultCard
                        key={`${key} - ${item}`}
                        reportType={key}
                        reportValue={item}
                      />
                    ));
                  }
                })}
              </Stack>
            </>
          )}

          {isLoading && (
            <Stack spacing={2}>
              {Array.from({
                length: limit,
              }).map((_, index) => (
                <ContactResultSkeleton key={index} />
              ))}
            </Stack>
          )}

          {isError && (
            <Text variant="body1" color="error" sx={{ mt: 2 }}>
              An error occurred while fetching the data. Please try again later.
            </Text>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
