import { Box, styled } from '@ltvco/refresh-lib/theme';

export const RatsContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '58px',
  backgroundColor: '#fff',
  zIndex: 15,
  '.rfrsh-btn': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },

    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

