import {
  useReport,
  Education,
  JobSection,
  SocialMediaSection,
  DesktopNav,
  ReportLoading,
  PotentialOwners,
  PossiblePhotosSection,
  PhoneSection,
  EmailSection,
  AddressHistory,
  UsernameOverview,
  ReportOptions,
  ReportActionsTopBar,
  ReportNavMobile,
  ReportFactory,
  NotesSection,
  ReportRating,
  RelatedReportsSection,
  ReportNullState,
  DebugMenu,
} from '@ltvco/refresh-lib/v1';
import { Grid } from '@ltvco/refresh-lib/theme';
import { DateUtil, isZeroed, useScrollToSectionOnNavigate } from '@ltvco/refresh-lib/utils';
import { getSocialNavLinkData } from 'navLinkData/socialNavLinkData';
import { useState } from 'react';
import type { ReportRouteProps } from './types';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import { RatsContainer } from './components';
import { UsernameReportOverview } from '@ltvco/refresh-lib/v2';

interface SocialReportProps extends ReportRouteProps {}

const reportType = 'username';

export function SocialReportPage({ permalink }: SocialReportProps) {
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={9} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'username');

  if (!report) return <ReportLoading menuItems={9} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    images,
    relatives,
  } = owner;

  const socialNavLinkData = getSocialNavLinkData(
    owner,
    report?.data?.people?.length ?? 0
  );

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  document.title = `${report.username} - PeopleSmart`;

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <DesktopNav
            navLinkData={socialNavLinkData}
            reportDataPoint={ownerName || report.username}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} marginTop={3}>
          <RatsContainer >
            <ReportActionsTopBar
              reportPermaLink={permalink}
              reportTitle="Username"
              reportType={reportType}
              deleteCurrentReport={(permalink: string) => permalink}
            />
          </RatsContainer>
          <UsernameReportOverview
            dateUpdated={reportUpdateDate}
            reportType={reportType}
            username={report.username}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) =>
              handleSetReportOptions({ showHighConfidenceDataOnly: value })
            }
          />
          <PotentialOwners
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || report.username}
            permalink={permalink}
          />
          <PossiblePhotosSection
            personName={ownerName || report.username}
            images={images || []}
          />
          <PhoneSection
            phonesCount={phones.length}
            personName={ownerName || report.username}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSection
            emailsCount={emails.length}
            personName={ownerName || report.username}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistory
            addresses={addresses}
            addressCount={addresses.length}
            personName={ownerName || report.username}
            permalink={permalink}
            showCityAndStateAddress={true}
          />
          <JobSection
            personName={ownerName || report.username}
            jobs={jobs}
            permalink={permalink}
          />
          <Education
            personName={ownerName || report.username}
            educations={educations}
            permalink={permalink}
          />
          <SocialMediaSection
            personName={ownerName || report.username}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <NotesSection permalink={permalink} />
          <RelatedReportsSection
            reportTitle={report.username}
            reportType="username"
            reportOwner={ownerName || report.username}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
            hasDefaultContactSearch={true}
          />
          <ReportRating
            permalink={permalink}
            rating={report.data.meta?.rating || null}
          />
        </Grid>
      </Grid>
      <ReportNavMobile navLinkData={socialNavLinkData} />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
