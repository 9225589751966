import {
  AppConfig,
} from '@ltvco/refresh-lib/ctx';
import {
  NeedAssistanceSidebar,
  useSnackbar,
  closeSnackbar,
  EmailSettingsCard,
  MonitoredReportsModal,
  useAccount,
} from '@ltvco/refresh-lib/v1';
import {
  Button,
  Text,
  Grid,
  Link,
} from '@ltvco/refresh-lib/theme';
import { getFirstRecordSearch, DateUtil, FirstRecordSearch } from '@ltvco/refresh-lib/utils';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { constants } from 'appConstants';
import { SidenavContainer } from '../components/sidenav/SidenavContainer';
import { CookiesStorage } from '@ltvco/refresh-lib';
import { ReactivatePausedAccountCta } from '@ltvco/refresh-lib/payments';

export function DashboardParent({
  contactUsHandler,
  handleLogout,
}: {
  contactUsHandler: () => void;
  handleLogout: () => void;
}) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { routingUtils } = useContext(AppConfig);
  const cookieStorage = new CookiesStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const dateUtil = new DateUtil();
  const currentDate = new Date();

  // State
  const [monitoredReportsOpen, setMonitoredReportsOpen] = useState(false);

  const { data: account } = useAccount(true);

  function closeMonitoredReportsModal() {
    setMonitoredReportsOpen(false);
  }

  useEffect(() => {
    if (!account) return;

    const firstRecordSearchData = getFirstRecordSearch();
    cookieStorage.removeValue('first_record_search');

    const reportLimit =
      account?.account?.subscription_info?.monthly_report_limit;
    const oneReportLimit = reportLimit && reportLimit === 1;

    if (firstRecordSearchData && !oneReportLimit && (firstRecordSearchData?.searchType === 'contact' || firstRecordSearchData?.searchType === 'contact_report')) {
      const queryParams = {
        name: firstRecordSearchData.name,
        company: firstRecordSearchData.company,
        state: firstRecordSearchData.state,
        title: firstRecordSearchData.job,
        seniority: firstRecordSearchData.seniority,
        industry: firstRecordSearchData.industry
      };

      navigate(routingUtils.searchContactUrl(queryParams));
      return;
    } else if (firstRecordSearchData && !oneReportLimit) {
      handleRedirectToReport(firstRecordSearchData);
    }

  }, [account]);

  useEffect(() => {
    if (localStorage.getItem('pausedAccountSnackbarId')) {
      try {
        closeSnackbar(
          JSON.parse(localStorage.getItem('pausedAccountSnackbarId') || '')
        );
      } catch (e) {
        console.log('error', e);
      }
    }
    handleSiteNotifications();
    handleReactivationForPausedAccountsBanner();
  }, [account]);

  function handleReactivationForPausedAccountsBanner() {
    if (!account) return;

    const isInPausedStatus = account?.meta?.subscription_state === 'paused';
    if (!isInPausedStatus) return;

    const today = currentDate.toISOString();
    const expirationDate = account?.account.subscription_info?.normalize_date;

    if (!expirationDate) return;

    const isAfterCurrentDate = dateUtil.isAfter(today, expirationDate);

    const expiredMessage = (
      <Text>
        <b>
          Your account expired on{' '}
          {dateUtil.parseDateFromString(
            account?.account.subscription_info?.normalize_date,
            'M/d/y'
          )}{' '}
          . Reactivate to regain access.
        </b>
      </Text>
    );

    const cancelledMessage = (
      <Text>
        <b>
          Your account with member ID {account?.account.user_info.user_code} has
          been cancelled.
        </b>{' '}
        <br /> You have until{' '}
        {dateUtil.parseDateFromString(
          account?.account.subscription_info?.normalize_date,
          'M/d/y'
        )}{' '}
        to continue running reports. Please reactivate now to maintain access.
        Visit{' '}
        <Link target="_blank" href="https://peoplesmart.com/rf/reactivation">
          here
        </Link>{' '}
        to reactivate.
      </Text>
    );

    const message = isAfterCurrentDate ? expiredMessage : cancelledMessage;

    const pausedAccountSnackbarId = enqueueSnackbar(message, {
      variant: 'info',
      persist: true,
      action: getCloseButton(() => {
        closeSnackbar(pausedAccountSnackbarId);
      }),
    });

    localStorage.setItem(
      'pausedAccountSnackbarId',
      JSON.stringify(pausedAccountSnackbarId)
    );
  }

  function getCloseButton(onClick: () => void) {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        sx={(theme) => ({
          fontsize: theme.spacing(0.75),
          maxHeight: '40px',
          width: '125px',
          color: `${theme.palette.success.contrastText} !important`,
          backgroundColor: `${theme.palette.success.main} !important`,
        })}
      >
        Close
      </Button>
    );
  }
  function stringExistsAndItsValueIsNotUndefined(input: string) {
    return Boolean(input) && input !== 'undefined';
  }

  function handleRedirectToReport(firstRecordSearchData: FirstRecordSearch) {
    let queryParams = '';

    if (firstRecordSearchData.searchType === 'phone') {
      queryParams = `phone=${firstRecordSearchData.phone_number}`;
    }
    if (firstRecordSearchData.searchType === 'email') {
      queryParams = `email=${firstRecordSearchData.email}`;
    }
    if (firstRecordSearchData.searchType === 'username') {
      queryParams = `username=${firstRecordSearchData.username}`;
    }
    if (firstRecordSearchData.searchType === 'phone-fraud') {
      queryParams = `phone=${firstRecordSearchData.phone_number}`;
    }
    if (firstRecordSearchData.searchType === 'email-fraud') {
      queryParams = `email=${firstRecordSearchData.email}`;
    }
    if (firstRecordSearchData.searchType === 'ip-fraud') {
      queryParams = `ip_address=${firstRecordSearchData.ip_address}`;
    }
    if (firstRecordSearchData.searchType === 'url-fraud') {
      queryParams = `url=${firstRecordSearchData.url}`;
    }

    if (queryParams.length > 0) {
      return navigate(
        `/report/${firstRecordSearchData.searchType}?${queryParams}`
      );
    }
  }

  function handleSiteNotifications() {
    if (!account) return;
    const siteNotification = account?.meta?.notification;
    if (!siteNotification) return;
    const siteNotificationSnackbarId = enqueueSnackbar(
      <Text>
        <b>{siteNotification.title}</b> <br /> {siteNotification.message}
      </Text>,
      {
        variant: 'info',
        persist: true,
        action: (
          <Button
            onClick={() => {
              closeSnackbar(siteNotificationSnackbarId);
            }}
            variant="contained"
            sx={(theme) => ({
              fontsize: theme.spacing(0.75),
              maxHeight: '40px',
              width: '125px',
              color: `${theme.palette.success.contrastText} !important`,
              backgroundColor: `${theme.palette.success.main} !important`,
            })}
          >
            Close
          </Button>
        ),
      }
    );
  }

  const isIdentityHub = location.pathname.includes('/dashboard/identity-hub');
  const isMonitoringPage = location.pathname.includes('/dashboard/monitoring');
  const showRightRail = isMonitoringPage;

  const RightRailComponent = (
    <Grid
      item
      container
      xs={12}
      md={3}
      lg={3}
      spacing={1}
      direction="column"
      marginTop={2}
      maxWidth={{ xs: '100%', md: '299px' }}
    >
      {location.pathname.includes('/dashboard/billing') && (
        <Grid
          item
          width={'100%'}
          order={{ xs: 6, md: 1 }}
          className="needs-assistance"
        >
          <NeedAssistanceSidebar
            logout={handleLogout}
            supportImgUrl={constants.images.img24hr}
            contactUsHandler={contactUsHandler}
          />
        </Grid>
      )}
      {isMonitoringPage && (
        <Grid item width={'100%'} order={2}>
          <EmailSettingsCard
            currentInstantAlertsSetting={
              account?.account?.alert_me.settings.instant_enabled || false
            }
            currentDigestAlertsSetting={
              account?.account?.alert_me.settings.digest_enabled || false
            }
            handleMonitoredReports={() => setMonitoredReportsOpen(true)}
          />
          <MonitoredReportsModal
            isOpen={monitoredReportsOpen}
            onClose={() => closeMonitoredReportsModal()}
          />
        </Grid>
      )}
      {account?.meta?.subscription_state === 'paused' && (
        <Grid item order={5}>
          <ReactivatePausedAccountCta />
        </Grid>
      )}
    </Grid>
  );

  const RightRail = showRightRail ? RightRailComponent : <></>;

  return (
    <SidenavContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid
          item
          xs={12}
          md={showRightRail ? 8 : 12}
          lg={showRightRail ? 8 : 12}
          maxWidth={{
            xs: '100%',
            md: showRightRail ? '911px !important' : '100%',
          }}
        >
          <Outlet />
        </Grid>
        {RightRail}
      </Grid>
    </SidenavContainer>
  );
}
