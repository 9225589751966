import { Stack, Text } from '@ltvco/refresh-lib/theme';
import {
  AddToContactsAction,
  ListAction,
  MenuItem,
  MonitorAction,
  MoreAction,
  PdfAction,
  RecentReportSerialized,
  SaveAction,
} from '@ltvco/refresh-lib/v2';
import { DescriptionDates } from './DescriptionDates';

type RecentReportsCardDescriptionProps = {
  report: RecentReportSerialized;
  deleteCurrentReport: (permalink: string) => void;
  hideRats?: boolean;
  isOnDashboard?: boolean;
};

const hidePDFReportTypes = [
  'username',
  'obituary',
  'unclaimed-money',
  'ancestor',
];

const fraudReportTypes = [
  'phone fraud',
  'email fraud',
  'ip fraud',
  'url fraud',
];

export const RecentReportsCardDescription: React.FC<
  RecentReportsCardDescriptionProps
> = ({
  report,
  deleteCurrentReport,
  hideRats = false,
  isOnDashboard = false,
}) => {
  const { reportTypeFormatted, id: reportPermaLink, reportData } = report;
  const reportType = reportTypeFormatted.toLowerCase();
  const hidePDF =
    fraudReportTypes.includes(reportType.toLocaleLowerCase()) ||
    hidePDFReportTypes.includes(reportType.toLocaleLowerCase());
  let subtitle = report.detailsCityState;
  if (reportType === 'vehicle') {
    subtitle = report.reportData;
  }
  if (reportType === 'phone') {
    if (report.firstName && report.lastName && report.detailsCityState) {
      subtitle = `${report.firstName} ${report.lastName} - ${report.detailsCityState}`;
    }
    if (report.firstName && report.lastName) {
      subtitle = `${report.firstName} ${report.lastName}`;
    }
  }

  const contactDetails = {
    number: report.details.phone_number,
    firstName: report.details.first_name,
    lastName: report.details.last_name,
    email: report.details.email,
    address: report.details.address,
    jobTitle: report.details.job_title,
    company: report.details.company,
  };

  const filterMenuItems = (items: MenuItem[]) => {
    return items.filter(
      (item) => item.display === 'Notes' || item.display === 'Delete'
    );
  };

  const showAddToContact = reportType?.toLocaleLowerCase() === 'contact pro';


  return (
    <Stack spacing={1}>
      <Stack spacing={0.5}>
        {subtitle && <Text>{subtitle}</Text>}
        <Text>{report.reportTypeFormatted} Report</Text>
        <DescriptionDates
          updatedDate={report.updatedDate}
          createdDate={report.createdDate}
          isOnDashboard={isOnDashboard}
          lastViewedDate={report.lastViewedDate}
        />
      </Stack>
      <Stack direction="row" spacing={1} ml={'-7px !important'}>
        {!hideRats && (
          <>
            {showAddToContact &&(
              <AddToContactsAction
              {...contactDetails}
              onReportCard
              disableCollapse
            />)}
            <SaveAction
              reportPermaLink={reportPermaLink}
              onReportCard
              disableCollapse
              reportType={reportType}
              section="recent_reports_rats"
              eventName="feature_engagement"
            />
            {reportType !== 'username' && (
              <MonitorAction
                reportPermaLink={reportPermaLink}
                reportType={reportType}
                onReportCard
                disableCollapse
                section="recent_reports_rats"
                eventName="feature_engagement"
              />
            )}
            <ListAction
              reportTitle={
                reportTypeFormatted.toLowerCase() === 'vehicle' &&
                report.detailsCityState
                  ? report.detailsCityState
                  : reportData
              }
              reportPermalink={reportPermaLink}
              onReportCard
              disableCollapse
              reportType={reportType}
              section="recent_reports_rats"
              eventName="feature_engagement"
            />
            {!hidePDF && (
              <PdfAction
                reportPermalink={reportPermaLink}
                onReportCard
                disableCollapse
                reportType={reportType}
                section="recent_reports_rats"
                eventName="feature_engagement"
              />
            )}
            <MoreAction
              filterMenuItems={filterMenuItems}
              deleteCurrentReport={deleteCurrentReport as () => void}
              reportPermaLink={reportPermaLink}
              reportType={reportType}
              onReportCard
              disableCollapse
              section="recent_reports_rats"
              eventName="feature_engagement"
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
