import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createSessionCookie, createRefCookie } from '@ltvco/refresh-lib/utils';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import LocationChangeListener from 'components/locationChangeListener/LocationChangeListener';
import '@ltvco/refresh-lib/dist/lib/style.css';
const prefix = import.meta.env.VITE_PATH_PREFIX;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={prefix}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <LocationChangeListener />
        <App />
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
createSessionCookie();
createRefCookie();
