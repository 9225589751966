import { useLocation } from 'react-router-dom';
import { SideNavigation } from '@ltvco/refresh-lib/v1';
import { AppConstants } from '@ltvco/refresh-lib/ctx';
import { useContext } from 'react';

export function Sidenav({ onReport }: { onReport?: boolean }) {
  const {
    config: { sideMenuItems },
  } = useContext(AppConstants);
  const location = useLocation();
  return (
    <SideNavigation
      menuItems={sideMenuItems}
      matchingRoute={location.pathname}
      onReport={onReport}
      hideItems={[
        { name: 'myGarage', routesToHide: ['dashboard', 'report'] },
        { name: 'myAccount', routesToHide: ['report'] },
      ]}
    />
  );
}
