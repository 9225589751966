import {
  AccountInformation,
  GenericCtaCard,
  LimitedPlanProgress,
  MyAccountHeader,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { Grid, Box } from '@ltvco/refresh-lib/theme';
import { useEffect, useState } from 'react';
import {
  AccountInformationOptions,
  GenericCtaCardProps,
  editOptionClicked,
} from './BillingConstants';
import { AccountEditModals } from 'components/myAccount/AccountEditModals';
import { useNavigate } from 'react-router-dom';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import {
  PaymentsSelectionModal,
  ViewOrderHistory,
  RadioPaymentsModal,
  PayPalRedirectionModal
} from '@ltvco/refresh-lib/payments';

export function Billing() {
  const navigate = useNavigate();
  const {
    session: { account },
  } = useSession();

  useEffect(() => {
    document.title = 'My Account - PeopleSmart';
  }, []);

  const {
    isLimitedUser,
    isUnlimitedUser,
    reportLimit,
    reportsRun,
    reportsRunPercentage,
    isLoading,
  } = useLimitedPlanInfo();

  const [openPaymentsSelectionModal, setOpenPaymentsSelectionModal] =
    useState<boolean>(false);

  const [openPaymentsUpdateModal, setOpenPaymentsUpdateModal] =
    useState<boolean>(false);

  //Paypal Redirection modal
  const [openPaypalRedirection, setOpenPaypalRedirection] =
    useState<boolean>(false);
  const [paypalState, setPaypalState] = useState<'success' | 'canceled'>(
    'success'
  );
  const togglePaypalRedirectionModal = () =>
    setOpenPaypalRedirection(!openPaypalRedirection);

  const [editing, setEditing] = useState<string | null>('');

  const { ctaCardProps, length } = GenericCtaCardProps(navigate);

  const togglePaymentsSelectionModal = () =>
    setOpenPaymentsSelectionModal(!openPaymentsSelectionModal);

  const togglePaymentsUpdateModal = () => {
    setOpenPaymentsUpdateModal(!openPaymentsUpdateModal);
    if (!openPaymentsSelectionModal) {
      togglePaymentsSelectionModal();
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('bvppcanc')) {
      setPaypalState('canceled');
      setOpenPaypalRedirection(true);
      return;
    }
    if (urlParams.get('bvpp') && urlParams.get('token')) {
      setPaypalState('success');
      setOpenPaypalRedirection(true);
    }
  }, []);

  const finishEditing = () => {
    setEditing('');
  };

  function handleAddPaymentMethod() {
    setOpenPaymentsSelectionModal(false);
    setOpenPaymentsUpdateModal(true);
  }

  const has_sso_connections = account?.account?.user_info?.has_sso_connections;

  let updatedItems = has_sso_connections
    ? AccountInformationOptions.filter(
        (item) => item.constantValue !== 'CHANGE_PASSWORD'
      )
    : AccountInformationOptions;
  if (has_sso_connections) {
    updatedItems.push({
      name: 'Send Password Reset Email',
      display: 'Send Password Reset Email',
      value: 'Send Password Reset Email',
      constantValue: 'PASSWORD_RESET_EMAIL',
    });
  }

  return (
    <>
      <MyAccountHeader isUnlimited={isUnlimitedUser} />
      {isLimitedUser && !isLoading && (
        <LimitedPlanProgress
          progressValue={reportsRunPercentage}
          reportsLimit={reportLimit}
          reportsRun={reportsRun}
          link="/upgrade/plan"
        />
      )}

      <Grid container spacing={2} justifyContent="center">
        {[...Array(length)].map((_x, index) => (
          <Grid item xs={12} md={6} key={index}>
            <GenericCtaCard {...ctaCardProps[index]}></GenericCtaCard>
          </Grid>
        ))}
      </Grid>

      <AccountEditModals editing={editing} finishEditing={finishEditing} />
      <Box
        mt={2.5}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <AccountInformation
          editOptions={updatedItems}
          editOptionClick={(value: string | null) => {
            editOptionClicked(value, setOpenPaymentsSelectionModal, setEditing, updatedItems);
          }}
        />
      </Box>
      <ViewOrderHistory />
      <PaymentsSelectionModal
        open={openPaymentsSelectionModal}
        onClose={togglePaymentsSelectionModal}
        onAddPaymentMethod={handleAddPaymentMethod}
      />
      <RadioPaymentsModal
        isOpen={openPaymentsUpdateModal}
        onCloseHandle={togglePaymentsUpdateModal}
      />
      <PayPalRedirectionModal
        paypalState={paypalState}
        isOpen={openPaypalRedirection}
        onCloseHandle={togglePaypalRedirectionModal}
      />
    </>
  );
}
