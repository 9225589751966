import { Box, styled } from '@ltvco/refresh-lib/theme';
import { Sidenav } from 'components/sidenav/Sidenav';
import { SsoPromoSideBar} from '@ltvco/refresh-lib/v1';
import { SideBarMigrationCta } from '@ltvco/refresh-lib/v2';

const PageContent = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.default,
  alignItems: 'stretch',
  minHeight: '80vh',
  position: 'relative',
}));

const SideBar = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'none',
  flex: '1',

  [theme.breakpoints.up('md')]: {
    width: '250px',
    minWidth: '280px',
    display: 'block',
  },
}));

const Content = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  padding: theme.spacing(3.5, 2),
  backgroundColor: theme.palette.background.paper,
  width: '100%',

  [theme.breakpoints.up('md')]: {
    maxWidth: '1296px',
    width: 'calc(100% - 250px)',
    padding: theme.spacing(3.5),
  },
}));

export function SidenavContainer({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <PageContent>
      <SideBar>
        <Box
          position={'sticky'}
          top={80}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <Sidenav />
          <SsoPromoSideBar />
          <SideBarMigrationCta />
        </Box>
      </SideBar>
      <Content>{children}</Content>
    </PageContent>
  );
}
