import { Text, Box, Card, Grid } from '@ltvco/refresh-lib/theme';
import React from 'react';
import { getFraudSearchSubtitleText } from '../../components/reports/fraud/utils';
import { FraudSearchContainer, SearchFormProps } from '@ltvco/refresh-lib/v1';
import imgFraudScan1 from 'images/fraud-scan-1.svg';
import imgFraudScan2 from 'images/fraud-scan-2.svg';
import {
  FullHeightCard,
  HowItWorksGridItem,
  HowItWorksStack,
  ScanImage1,
  ScanImage2,
  SectionHeader,
  StyledCard,
  StyledIconBox,
} from './fraud-components';
import { AutoGraph, CleaningServices, ContentPasteSearch, EmojiEvents, FactCheck, InsertLink, LocationOn, Mail, PhoneIphone, Public, Radar, Shield } from '@mui/icons-material';
import { theme } from 'theme';

interface CommonReportProps {
  onSearchSuccess?: SearchFormProps['onSuccess'];
  onSearchError?: SearchFormProps['onError'];
}

export function Fraud({ onSearchSuccess, onSearchError }: CommonReportProps) {
  const [tabState, setTabState] = React.useState<string>('');

  document.title = 'FraudScan - PeopleSmart';

  return (
    <Box>
      <Card variant="outlined" sx={{ position: 'relative' }}>
        <Text
          variant="h1"
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <Radar sx={{ color: theme.palette.primary.main }} fontSize="large" />
          FraudScan
        </Text>

        <Text
          sx={(theme) => ({
            marginBottom: theme.spacing(5),
            maxWidth: { xs: '100%', sm: 400, md: 300, lg: 500 },
          })}
        >
          {getFraudSearchSubtitleText(tabState)}
        </Text>

        <Text fontWeight="bold" mb={2}>
          What would you like to check?
        </Text>

        <FraudSearchContainer
          onSearchSuccess={onSearchSuccess}
          onSearchError={onSearchError}
          onSearchChange={setTabState}
          displayBulkUpload={true}
        />

        <ScanImage1 src={imgFraudScan1} alt="Scan" />
      </Card>

      <Card
        variant="outlined"
        sx={(theme) => ({
          background: `linear-gradient(102.42deg, ${theme.palette.background.paper} 15.39%, ${theme.palette.secondary.main} 84.61%)`,
        })}
      >
        <SectionHeader variant="h1">How It Works</SectionHeader>

        <Grid
          container
          sx={(theme) => ({
            background: theme.palette.background.paper,
          })}
        >
          <HowItWorksGridItem>
            <HowItWorksStack>
              <Box>
                <PhoneIphone />
              </Box>

              <Box>
                <Text variant="h4">Phone Number Verification</Text>

                <Text variant="body2" textAlign="left">
                  Find signals needed to verify a phone number including its
                  likelihood to be fraudulent, threat level, active status,
                  whether it appears on a Do Not Call list, and comments from
                  our network of users.
                </Text>
              </Box>
            </HowItWorksStack>
          </HowItWorksGridItem>

          <HowItWorksGridItem>
            <HowItWorksStack>
              <Box>
                <Mail />
              </Box>

              <Box>
                <Text variant="h4">Email Verification</Text>
                <Text variant="body2" textAlign="left">
                  Verify the legitimacy or threat level of an email address with
                  information such as its likelihood to be fraudulent, valid,
                  whether it can receive emails, and various threat signals
                  indicating malicious/spammy behavior.
                </Text>
              </Box>
            </HowItWorksStack>
          </HowItWorksGridItem>

          <HowItWorksGridItem>
            <HowItWorksStack>
              <Box>
                <LocationOn />
              </Box>

              <Box>
                <Text variant="h4">IP Lookup</Text>
                <Text variant="body2" textAlign="left">
                  Check global IP addresses for location, risk signals that
                  could indicate a history of abusive or malicious behavior, and
                  signs that the user may be masking their true identity.
                </Text>
              </Box>
            </HowItWorksStack>
          </HowItWorksGridItem>

          <HowItWorksGridItem>
            <HowItWorksStack>
              <Box>
                <InsertLink />
              </Box>

              <Box>
                <Text variant="h4">URL Scanning</Text>
                <Text variant="body2" textAlign="left">
                  Help protect yourself by scanning URLs for suspicious or
                  malicious behavior, adult content, and redirects that could
                  lead you to a risky destination online.
                </Text>
              </Box>
            </HowItWorksStack>
          </HowItWorksGridItem>
        </Grid>
      </Card>

      <Card variant="outlined">
        <SectionHeader variant="h1">Why Choose Us</SectionHeader>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FullHeightCard>
              <StyledIconBox>
                <EmojiEvents sx={{ color: theme.palette.primary.main }} fontSize="large" />
              </StyledIconBox>

              <Text variant="h3" textAlign="center" m={0}>
                Industry Leading Partners
              </Text>
              <Text textAlign="center">
                We work with an industry leader known for its{' '}
                <b>unmatched data accuracy</b>, powered by software built by
                ex-NSA engineers.
              </Text>
            </FullHeightCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <FullHeightCard>
              <StyledIconBox>
                <Public sx={{ color: theme.palette.primary.main }} fontSize="large" />
              </StyledIconBox>

              <Text variant="h3" textAlign="center" m={0}>
                Vast Global Coverage
              </Text>
              <Text textAlign="center">
                With access to hundreds of different signals across{' '}
                <b>billions of global data points</b>, our coverage beats the
                competition.
              </Text>
            </FullHeightCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <FullHeightCard>
              <StyledIconBox>
                <AutoGraph sx={{ color: theme.palette.primary.main }} fontSize="large" />
              </StyledIconBox>

              <Text variant="h3" textAlign="center" m={0}>
                {' '}
                Fresh Data
              </Text>
              <Text textAlign="center">
                While others use data that may be months old, we use data{' '}
                <b>as fresh as the last few hours</b>.
              </Text>
            </FullHeightCard>
          </Grid>
        </Grid>
      </Card>

      <Card variant="outlined">
        <SectionHeader variant="h1">How FraudScan Can Help You</SectionHeader>

        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ScanImage2 src={imgFraudScan2} alt="Scan" />
          </Grid>
          <Grid item xs={12} md={8}>
            <StyledCard variant="outlined">
              <FactCheck />

              <Text>
                <b>Verifying the legitimacy</b> of phone numbers, emails, and IP
                addresses
              </Text>
            </StyledCard>

            <StyledCard variant="outlined">
              <CleaningServices />

              <Text>
                <b>Cleaning up inaccurate or outdated information</b> in contact
                lists, or flagging numbers that are on the “Do Not Call” list
              </Text>
            </StyledCard>

            <StyledCard variant="outlined">
              <Shield />

              <Text>
                <b>Protecting</b> against bad actors and malicious activity
              </Text>
            </StyledCard>

            <StyledCard variant="outlined">
              <ContentPasteSearch />

              <Text>
                <b>Finding more details on</b> the phone numbers, email
                addresses, IP addresses, and URLs you interact with every day
              </Text>
            </StyledCard>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
